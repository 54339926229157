// @fileoverview : 경남-KPI > 제조리드타임(P)

import { KPIActionHeader } from "@/features/kpi/actionHeader";
import { useKPIData } from "@/features/kpi/hook/useKPIData";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import useColumnList from "@/hooks/useColumnList";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { Flex } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const KPILeadTime = Object.assign(
  {},
  {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    KPIActionHeader: KPIActionHeader,
    CustomTable: CustomTable,
  },
);

export const KPILeadtimePage = () => {
  const today = dayjs().subtract(1, "day").startOf("day").toDate();
  const oneWeekAgo = dayjs().subtract(7, "day").startOf("day").toDate();
  const { t } = useTranslation();
  const columnlist = useColumnList();

  const [content, setContent] = useState<any[]>([]);
  const [selectList, setSelectList] = useState<Set<number>>(new Set());
  const [item, setItem] = useState<string | null>("");
  const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
  const [averageLeadTime, setAverageLeadTime] = useState<string>("");

  const { itemData, leadTimeData } = useKPIData({
    item,
    stateDate,
    options: { fetchLeadTime: true },
  });

  useEffect(() => {
    if (leadTimeData?.data) {
      const transformedData = leadTimeData.data.map((leadTime: any) => ({
        productionPlanId: leadTime.productionPlanId,
        scheduledAt: timeUtil(leadTime.scheduledAt),
        itemCode: leadTime.itemCode,
        itemSpec: leadTime.itemSpec,
        workerName: leadTime.workerName,
        workEndTime: DatetimeUtilMinute(leadTime.workEndTime),
        workStartTime: DatetimeUtilMinute(leadTime.workStartTime),
        leadTime: leadTime.leadTime,
      }));

      // leadTime의 평균 계산
      const totalLeadTime =
        leadTimeData?.data?.reduce(
          (sum: number, leadTime: any) => sum + parseFloat(leadTime.leadTime),
          0,
        ) ?? 0;
      const averageLeadTime = (totalLeadTime / leadTimeData.data.length).toFixed(2);
      setAverageLeadTime(averageLeadTime);
      setContent(transformedData);
    } else {
      setContent([]);
    }
  }, [leadTimeData]);

  const TableHeader = {
    machine: [t("item.code"), t("item.name"), t("item.spec"), t("KPI.leadtime.averageTime")],
  };

  const BodyData = {
    machine: [
      {
        [t("item.code")]: itemData?.code ?? "-",
        [t("item.name")]: itemData?.name,
        [t("item.spec")]: itemData?.spec ?? "-",
        [t("KPI.leadtime.averageTime")]: averageLeadTime === "NaN" ? "-" : averageLeadTime,
      },
    ],
  };

  return (
    <Flex gap="md" direction={"column"} p="sm">
      <KPILeadTime.Header>
        <KPILeadTime.HeaderTitle>{t("KPI.leadtime.leadtime")}(P)</KPILeadTime.HeaderTitle>
        <KPILeadTime.HeaderSubTitle>{t("KPI.leadtime.subTitle")}</KPILeadTime.HeaderSubTitle>
      </KPILeadTime.Header>
      <KPILeadTime.KPIActionHeader
        setItem={setItem}
        stateDate={stateDate}
        setStateDate={setStateDate}
      />
      <KPILeadTime.CustomTable headers={TableHeader.machine} data={BodyData.machine} />
      <GridTable
        headerList={columnlist.leadTimeItemTable}
        row={content}
        setRow={setContent}
        selectList={selectList}
        //@ts-ignore
        setSelectList={setSelectList}
        width={"100%"}
        maxWidth={"100%"}
        height={750}
        resizable={true}
      />
    </Flex>
  );
};
