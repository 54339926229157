import { ColumnHeaderProps } from "@/constants/columnHeader";
import { operatorMap } from "@/constants/filters";
import { useModal } from "@/features/modal/ModalStackManager";
import { MultiAutoCompleteSelector } from "@/features/standardLayout/MultiAutoCompleteSelector";
import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface FilterFormProps {
  searchFieldsHeader: ColumnHeaderProps[];
}

type SearchType = "text" | "number" | "enum";

const isIncludeReturnContent = (condition: string, content: string) => {
  if (condition.toLowerCase().includes("like")) {
    return `%${content}%`;
  }
  return content;
};

const findHeadersBySearchType = (searchType: string, headers: ColumnHeaderProps[]) => {
  return headers.find((header) => header.value === searchType);
};

export const FilterForm = ({ searchFieldsHeader }: FilterFormProps) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  // Multi 선택 시 필요한 boolean 상태
  const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);
  const [selectedMultiField, setSelectedMultiField] = useState<string[]>([]);

  const [selectedElement, setSelectedElement] = useState<string>("");
  const [selectedSearchType, setSelectedSearchType] = useState<SearchType>("text");
  const [selectedCondition, setSelectedCondition] = useState<string>("");
  const [selectedContent, setSelectedContent] = useState<string>("");

  const onSubmit = () => {
    const selectedObject = {
      [selectedElement]: isMultiSelect
        ? { $in: selectedMultiField }
        : { [selectedCondition]: isIncludeReturnContent(selectedCondition, selectedContent ?? "") },
    };

    closeModal(selectedObject);
  };

  const isDisabled = () => {
    if (!selectedElement) return true; // 필터링 요소가 선택되지 않으면 비활성화
    if (isMultiSelect) {
      return selectedMultiField.length === 0; // 다중 선택일 경우, 값이 없으면 비활성화
    }
    // 단일 필터링의 경우 조건과 값이 모두 입력되었는지 확인
    return !(selectedCondition && selectedContent);
  };

  useEffect(() => {
    const header = findHeadersBySearchType(selectedElement, searchFieldsHeader);
    setSelectedSearchType((header?.category as SearchType) ?? "text");
    setIsMultiSelect(header?.isFK || header?.isEnum || false);
  }, [searchFieldsHeader, selectedElement]);

  return (
    <Flex gap="md" direction="column" w="20rem" h="auto">
      <Select
        label={t("common.filteringElements")}
        data={searchFieldsHeader}
        value={selectedElement}
        onChange={(e) => setSelectedElement(e ?? "")}
        withinPortal
      />
      {!isMultiSelect && (
        <>
          <Select
            label={t("common.filteringConditions")}
            data={operatorMap.filter((item) => item.category === selectedSearchType)}
            value={selectedCondition}
            onChange={(e) => setSelectedCondition(e?.toString() ?? "")}
            withinPortal
          />
          <TextInput
            label={t("common.filteringValue")}
            value={selectedContent}
            onChange={(e) => setSelectedContent(e.currentTarget.value)}
          />
        </>
      )}
      {isMultiSelect && (
        <MultiAutoCompleteSelector
          multiFieldName={selectedElement}
          selectedMultiField={selectedMultiField}
          setSelectedMultiField={setSelectedMultiField}
        />
      )}

      <Flex justify="flex-end" gap="xs">
        <Button color="gray" onClick={() => closeModal(null)}>
          {t("common.cancel")}
        </Button>
        <Button onClick={() => onSubmit()} disabled={isDisabled()}>
          {t("common.create", { item: `${t("common.filter")}` })}
        </Button>
      </Flex>
    </Flex>
  );
};
