import { ColumnHeaderProps } from "@/constants/columnHeader";
import { sorterMap } from "@/constants/sorter";
import { useModal } from "@/features/modal/ModalStackManager";
import { Button, Flex, Select } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface SorterFormProps {
  searchFieldsHeader: ColumnHeaderProps[];
}

export const SorterForm = ({ searchFieldsHeader }: SorterFormProps) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();

  const [selectedElement, setSelectedElement] = useState<string>("");
  const [selectedCondition, setSelectedCondition] = useState<string>("");

  const onSubmit = () => {
    closeModal(`${selectedCondition}${selectedElement}`);
  };

  return (
    <Flex gap="md" direction="column">
      <Select
        label={t("common.sortElements")}
        data={searchFieldsHeader}
        value={selectedElement}
        onChange={(e) => setSelectedElement(e ?? "")}
        withinPortal
      />
      <Select
        label={t("common.sortConditions")}
        data={sorterMap}
        value={selectedCondition}
        onChange={(e) => setSelectedCondition(e?.toString() ?? "")}
        withinPortal
      />
      <Flex justify="flex-end" gap="xs">
        <Button color="gray" onClick={() => closeModal(null)}>
          {t("common.cancel")}
        </Button>
        <Button onClick={() => onSubmit()} disabled={!selectedElement}>
          {t("common.create", { item: `${t("common.sort")}` })}
        </Button>
      </Flex>
    </Flex>
  );
};
