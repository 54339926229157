// @fileoverview : 생산관리 > 작업지시
import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { usePostProductionPlanInputERPWorkOrder } from "@/api/productionPlan/usePostProductionPlanGetQuery";
import { setERPWorkRecord } from "@/api/works/useWorkRecordQuery";
import { customFunctions } from "@/config/customFunction";
import { WorkInputForm } from "@/features/inventory/components/Form/WorkInputForm";
import { LeftActionBar, RightActionBar } from "@/features/layout";
import { useModal } from "@/features/modal/ModalStackManager";
import { PigmentInputForm } from "@/features/pigment/form/PigmentInputForm";
import { ProductionPlanCreateForm } from "@/features/productionPlan/form/CreateForm";
import { QueryType } from "@/features/standardLayout/Context";
import { useQueryString } from "@/features/standardLayout/useQueryString";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { DefectTypeItem, DefectTypeSet } from "@/features/ui/DefectTypeSet";
import customAlert from "@/features/ui/alert/alert";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { WorkActionBar } from "@/features/workByManagement/WorkActionBar";
import { WorkHeader } from "@/features/workByManagement/WorkHeader";
import { WorkNotFound } from "@/features/workByManagement/WorkNotFound";
import { WorkPassArea } from "@/features/workByManagement/WorkPassArea";
import { WorkProductionPlanTable } from "@/features/workByManagement/WorkProductionPlanTable";
import { WorkProductionPlanTableHead } from "@/features/workByManagement/WorkProductionPlanTableHead";
import { WorkProductionPlanTableRow } from "@/features/workByManagement/WorkProductionPlanTableRow";
import { WorkSiteSelect } from "@/features/workByManagement/WorkSiteSelect";
import { WorkTable } from "@/features/workByManagement/WorkTable";
import { WorkTableActionBar } from "@/features/workByManagement/WorkTableActionBar";
import { WorkTableBody } from "@/features/workByManagement/WorkTableBody";
import { WorkTableHead } from "@/features/workByManagement/WorkTableHead";
import { WorkTableRow } from "@/features/workByManagement/WorkTableRow";
import { WorkTableWrapper } from "@/features/workByManagement/WorkTableWrapper";
import { WorkUpdateForm } from "@/features/workByManagement/WorkUpdateForm";
import useWorkMutation from "@/features/workByManagement/api/useWorkMutation";
import { workPage } from "@/features/workByManagement/api/useWorkPageQuery";
import { SocketData } from "@/features/workByManagement/context/WorkProvider";
import useAutoResetState from "@/features/workByManagement/hooks/useAutoResetState";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub } from "@/hooks";
import { useLoader } from "@/hooks/useLoader";
import useLocalStorage from "@/hooks/useLocalStorage";
import { BASE_URL } from "@/instance/axios";
import { getStartAndEndOfDateRange } from "@/utils/dateTimeUtil";
import styled from "@emotion/styled";
import { Button, Flex, Menu, Pagination, Select } from "@mantine/core";
import {
  DefaultApiWmsFlushPutRequest,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar, IconSettings } from "@tabler/icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSocket } from "socket.io-react-hook";

const Work = {
  ActionBar: WorkActionBar,
  LeftActionBar: LeftActionBar,
  RightActionBar: RightActionBar,
  SiteSelect: WorkSiteSelect,
  Select: Select,
  Button: Button,
  WorkHeader: WorkHeader,
  WorkTableActionBar: WorkTableActionBar,
  WorkTableWrapper: WorkTableWrapper,
  WorkProductionPlanTable: WorkProductionPlanTable,
  WorkProductionPlanTableHead: WorkProductionPlanTableHead,
  WorkProductionPlanTableRow: WorkProductionPlanTableRow,
  WorkTable: WorkTable,
  WorkTableHead: WorkTableHead,
  WorkTableBody: WorkTableBody,
  WorkTableRow: WorkTableRow,
};

const useableEquipmentAutoComplete = customFunctions.ADD_SETTING_EQUIPMENT_AUTOCOMPLETE;
const useableItemAutoComplete = customFunctions.ADD_SETTING_ITEM_AUTOCOMPLETE;
const useableErpWorkOrder = customFunctions.ADD_INPUT_ERP_WORK_ORDER;
const useableErpWorkRecord = customFunctions.ADD_ERP_WORK_RECORD;
const useablePigmentInput = customFunctions.ADD_PIGMENT_INPUT;

export const WorkByRows = () => {
  const state = useWorkState();
  const actions = useWorkActions();
  const { t } = useTranslation();
  const [page, setPage] = useQueryString<number>("page", 1);
  const [pageSize, setPageSize] = useQueryString<number>("pageSize", 10);
  const [startDate, setStartDate] = useQueryString<Date | null>("startDate", state.date[0]);
  const [endDate, setEndDate] = useQueryString<Date | null>("endDate", state.date[1]);
  const [equipmentCode, setEquipmentCode] = useQueryString<string | undefined>(
    "equipmentCode",
    state.equipmentCode,
  );
  const [itemCode, setItemCode] = useQueryString<string | undefined>("itemCode", state.itemCode);
  const [query, setQuery] = useQueryString<QueryType[]>("query", []);
  const [params, setParams] = useState<QueryType>({});

  const { openModal } = useModal();
  const [defectTypes, setDefectTypes] = useLocalStorage<DefectTypeItem[][]>("defectTypes", [
    [],
    [],
  ]);
  // useAutoResetState를 사용하여 상태를 관리
  const [updateSuccess, setUpdateSuccess] = useAutoResetState<boolean>(false, 50);

  const publish = usePub();
  const { updateQuantityMutate, updateDefectMutate, deleteMutate, putEquipmentCorrectionMutate } =
    useWorkMutation();
  const { setLoading, LoadingOverlay } = useLoader();
  const queryClient = useQueryClient();
  const { mutate: flushMutate } = useMutation(
    (params: DefaultApiWmsFlushPutRequest) =>
      mutateInventories.flush(params).mutationFn(params as DefaultApiWmsFlushPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
      },
    },
  );

  const [socketData, setSocketData] = useState<SocketData[]>([]);

  // 작업지시에서 외주 안보이게.
  const filterConditions = {
    ...(customFunctions.ADD_ADD_EXTERNAL_OUTSOURCING_WORK_ROW && {
      routingOutsourceId: { $eq: null },
    }),
  };

  const { data: workData, refetch } = useQuery(
    workPage.workItem({
      defectCodes: defectTypes && defectTypes[1],
      query: {
        $and: [
          {
            // 최근 작업일 기준으로 변경 (2024-07-31 jswon)
            lastWorkDate: {
              $between: getStartAndEndOfDateRange(startDate, endDate),
            },
          },
          ...(equipmentCode
            ? [
                {
                  equipmentCode: {
                    $eq: equipmentCode,
                  },
                },
              ]
            : []),
          ...(itemCode
            ? [
                {
                  itemCode: {
                    $eq: itemCode,
                  },
                },
              ]
            : []),
          filterConditions,
        ],
      },
      page: page,
      pageSize: pageSize,
      sort: "-id",
    }),
  );
  const works = workData?.rows;

  // URL 파라미터 업데이트
  useEffect(() => {
    const updatedParams = {
      page: page,
      startDate: startDate instanceof Date ? startDate.toISOString() : undefined,
      endDate: endDate instanceof Date ? endDate.toISOString() : undefined,
      equipmentCode: equipmentCode,
      itemCode: itemCode,
      pageSize: pageSize,
      query: JSON.stringify(query),
    };
    setParams((prev) => ({
      ...prev,
      ...updatedParams,
    }));
  }, [page, startDate, endDate, query, equipmentCode, itemCode]);

  const { socket, error } = useSocket(BASE_URL, {
    autoConnect: true,
  });

  useEffect(() => {
    actions.setResetState();
  }, []);

  useEffect(() => {
    works?.forEach((work) => {
      const roomName = work.equipmentCode ? `EC_${work.equipmentCode}` : "";
      socket.on(roomName, (message: SocketData) => {
        setSocketData((prev: SocketData[]) => {
          const newData = [
            ...prev,
            {
              ...message,
              workId: work.id,
            },
          ];
          return newData;
        });
      });

      socket.emit("call", "socket.join", { room: roomName }, function (err: any, res: any) {
        if (err) {
          console.error(err);
        }
      });
    });

    // 컴포넌트가 언마운트될 때 소켓의 이벤트 리스너를 제거
    return () => {
      works?.forEach((work) => {
        const roomName = work.equipmentCode ? `EC_${work.equipmentCode}` : "";
        socket.off(roomName);
      });
    };
  }, [socket, works]);

  useEffect(() => {
    if (works && works.length > 0) {
      actions.setTempWorks((prevWorks) => {
        const existingIds = new Set(prevWorks.map((work) => work.id));
        const newWorks = works.filter((work) => !existingIds.has(work.id));
        return [...prevWorks, ...newWorks];
      });
    }
  }, [works]);

  const handleUpdateQuantity = async (work: ProductionPlansGet200ResponseRowsInnerWorksInner) => {
    const quantityData = state.tempQuantity.find(
      (data) => data.key === (work.id && work.id.toString()),
    );

    if (work.id && quantityData?.value) {
      try {
        await updateQuantityMutate(
          {
            workId: work.id,
            worksWorkIdPerformancePutRequest: {
              accQuantity: quantityData.value,
            },
          },
          {
            onSuccess: () => {
              if (!state.tempDefects.length) {
                actions.setTempQuantity([]);
                setUpdateSuccess(true); // 성공적인 API 호출 후 상태 업데이트
                refetch(); // 데이터 다시 불러오기
                actions.setWorks((prevWorks) => []);
              }
            },
          },
        );
      } catch (error) {
        // 에러처리 (불량만 업뎃할 때는 완료수량 변화량이 없을 때 발생함. 이를 예외처리 해야됨-가능한 부분)
        alert(t("workMessage.updateFail"));
      }
    }
  };

  const handleUpdateDefects = async (work: ProductionPlansGet200ResponseRowsInnerWorksInner) => {
    // work에 들어있는 operationCode
    // 불량코드 조회 시 operationCode가 들어있어야 함.
    const defectData = state.tempDefects.find(
      (data) => data.id === (work.id && work.id.toString()),
    );
    // Update one defect at a time
    const updateDefectSequentially = async (defect: {
      key: string;
      value: string;
      label?: string;
      operationCode?: string;
    }) => {
      try {
        if (work.id) {
          // operationCode가 존재하고, work의 operationCode와 defect의 operationCode가 다를 때... 처리 확인 필요
          if (defect.operationCode && work?.routingData?.operationCode !== defect.operationCode) {
            // work의 operationCode와 defect의 operationCode가 같을 때는 업데이트 가능...
            alert(
              t("workMessage.defectFail", {
                itemName: work?.item?.name,
                defectLabel: defect?.label,
              }),
            );
          } else {
            await updateDefectMutate({
              workId: work.id,
              worksWorkIdDefectPutRequest: {
                defectCode: defect.key,
                accQuantity: defect.value,
              },
            });
          }
        }
      } catch (e: any) {
        console.error("Error updating defect: ", e);
        throw e; // 에러를 다시 throw하여 밖에서 처리할 수 있도록 합니다.
      }
    };

    if (work.id && defectData) {
      try {
        await Promise.all(defectData.defect.map((defect) => updateDefectSequentially(defect)));
        actions.setTempDefects([]);
        setUpdateSuccess(true);
        actions.setWorks((prevWorks) => []);
        refetch(); // 데이터 다시 불러오기
      } catch (e: any) {
        if (e.response?.data?.message === "defectQuantity is greater than startQuantity") {
          alert(t("workMessage.defectSaveFail"));
        } else {
          alert(t("workMessage.error"));
        }
      }
    }
  };

  const { mutateAsync: inputERPWorkOrder } = usePostProductionPlanInputERPWorkOrder();

  // 생산계획 가져오기
  const getProductionPlan = async () => {
    try {
      const erpWorkOrder = await inputERPWorkOrder();

      // 쿼리를 다시 불러오게 하기
      queryClient.invalidateQueries(["workPage"]);

      const {
        insert: workInsert,
        update: workUpdate,
        delete: workDelete,
      } = erpWorkOrder.data ?? { insert: [], update: [], delete: [] };
      const insertCount = Array.isArray(workInsert) ? workInsert.length : 0;
      const updateCount = Array.isArray(workUpdate) ? workUpdate.length : 0;
      const deleteCount = Array.isArray(workDelete) ? workDelete.length : 0;

      const alertParts = [
        insertCount ? insertCount + "건 연동" : "",
        updateCount ? updateCount + "건 갱신" : "",
        deleteCount ? deleteCount + "건 삭제" : "",
      ];
      const alertString = t("workMessage.getProductionPlan", {
        alertParts: alertParts.filter((part) => part).join(", "),
      });
      customAlert(alertString, t("workButton.getProductionPlan"), "info");
    } catch (e) {
      console.log(e);
    }
  };

  const postERP = async () => {
    const workData = state.works ?? [];

    if (workData.length) {
      const workIds = workData.map((work) => work.id);
      const workIdData = workIds.filter((workId): workId is number => workId !== undefined);

      setLoading(true);
      try {
        const result = await setERPWorkRecord({ workIds: workIdData });
        const { data } = result;

        if (data.success && data.success.length) {
          const workStr = data.success.join(", ");
          customAlert(
            t("workMessage.erpSuccess", { workStr: workStr }),
            t("workButton.erpPerformance"),
            "info",
          );
        }

        if (data.fail && data.fail.length) {
          const workStr = data.fail.join(", ");
          customAlert(
            t("workMessage", { workStr: workStr }),
            t("workButton.erpPerformance"),
            "red",
          );
        }
      } catch (error) {
        console.error("실적 전달 중 오류 발생:", error);
        customAlert(t("workMessage.erpError"), t("workButton.erpPerformance"), "red");
      } finally {
        // 로딩 종료
        setLoading(false);
      }
      actions.setWorks((prevWorks) => []);
      setUpdateSuccess(true);
      publish("unCheck");
    }
  };

  return (
    <WorkExampleContainer>
      <LoadingOverlay />
      <Work.ActionBar>
        <Work.LeftActionBar>
          {/* TODO: 현재 선택된 사업장 => 추가 필요 */}
          {/* <Work.Select data={[]} placeholder="사업장 선택" /> */}
          <Work.SiteSelect />
          {/* TODO: 현재 선택된 라인 => 추후 테이블 추가 필요하고 equipment에 lineId 추가 필요  */}
          <Work.Select data={[]} placeholder="라인 선택" />
          <Work.Button
            leftIcon={<IconCalendar />}
            onChange={() => {
              setPage(1);
              actions.setWorks(() => []);
              actions.setTempWorks(() => []);
            }}
            onClick={() => {
              openModal(
                <Calendar dates={state.date} type="range" />,
                null,
                t("workButton.dateSelect"),
                true,
              ).then((value) => {
                if (isCalendarDate(value)) {
                  actions.setDate(value);
                  setStartDate(value[0]);
                  setEndDate(value[1]);
                  actions.setWorks(() => []);
                  actions.setTempWorks(() => []);
                }
              });
            }}
          >
            {t("workButton.dateSelect")}
          </Work.Button>
        </Work.LeftActionBar>
        <Work.RightActionBar>
          <Menu shadow="md" width={150} position="bottom-end">
            <Menu.Target>
              <Button color="gray" rightIcon={<IconSettings />}>
                Settings
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  openModal(<DefectTypeSet />, null, t("workButton.defectSetting")).then(
                    (result) => {
                      // result 데이터가 특정 데이터일 경우 불량 타입 재조회 (불량 유형 설정을 완료했다는 의미)
                      if (Array.isArray(result)) {
                        setDefectTypes(result as DefectTypeItem[][]);
                      }
                    },
                  );
                }}
              >
                Settings
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Work.RightActionBar>
      </Work.ActionBar>
      {(useableEquipmentAutoComplete || useableItemAutoComplete) && (
        <Flex gap="xs">
          {useableEquipmentAutoComplete && (
            <EquipmentAutoComplete
              value={equipmentCode}
              onChange={(value) => {
                setEquipmentCode(value ?? "");
                setPage(1);
                actions.setWorks(() => []);
                actions.setTempWorks(() => []);
              }}
              placeholder={t("placeholder.select", { item: t("equipment.equipment") })}
            />
          )}
          {useableItemAutoComplete && (
            <ItemAutoComplete
              value={itemCode}
              onChange={(value) => {
                setItemCode(value ?? "");
                setPage(1);
                actions.setWorks(() => []);
                actions.setTempWorks(() => []);
              }}
              placeholder={t("placeholder.select", { item: t("item.item") })}
              getItemType={[
                ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT,
                ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
              ]}
            />
          )}
        </Flex>
      )}
      <Work.WorkHeader dateType="range" startDate={startDate} endDate={endDate} />
      <Work.WorkTableActionBar>
        <Work.LeftActionBar>
          <Work.Button
            onClick={() =>
              openModal(
                <ProductionPlanCreateForm />,
                {},
                t("workButton.productionPlanCreate"),
                true,
              ).then(() => refetch())
            }
          >
            {t("workButton.productionPlanCreate")}
          </Work.Button>
          <Work.Button
            variant="light"
            disabled={!(state.works && state.works.length === 1)}
            onClick={() => {
              const [work] = state.works;
              openModal(
                <WorkInputForm<"plan">
                  workRowData={work as ProductionPlansGet200ResponseRowsInnerWorksInner}
                />,
                null,
                t("workButton.materialInput"),
                true,
              );
            }}
          >
            {t("workButton.materialInput")}
          </Work.Button>
          <Work.Button
            variant="light"
            color="orange"
            disabled={!(state.works && state.works.length === 1)}
            onClick={() => {
              const [work] = state.works;
              flushMutate(
                {
                  wmsFlushPutRequest: {
                    locationCode:
                      (work as ProductionPlansGet200ResponseRowsInnerWorksInner)?.locationSetting
                        ?.toLocation?.code ?? "",
                  },
                },
                {
                  onSuccess: (res: any) => {
                    res.data.length > 0
                      ? customAlert(t("workMessage.flushSuccess"), t("workButton.flush"), "info")
                      : customAlert(t("workMessage.flushFail"), t("workButton.flush"), "red");
                    actions.setWorks((prevWorks) => []);
                  },
                },
              );
            }}
          >
            {t("workButton.flush")}
          </Work.Button>
          {useableErpWorkOrder && (
            <Work.Button variant="outline" color="violet" onClick={getProductionPlan}>
              {t("workButton.workOrder")}
            </Work.Button>
          )}
          {useableErpWorkRecord && (
            <Work.Button
              disabled={!(state.works && state.works.length >= 1)}
              variant="outline"
              color="teal"
              onClick={() => {
                postERP();
              }}
            >
              {t("workButton.workRecord")}
            </Work.Button>
          )}
          <Work.Button
            variant="light"
            color="teal"
            disabled={!(state.works && state.works.length === 1)}
            onClick={async () => {
              const [work] = state.works as ProductionPlansGet200ResponseRowsInnerWorksInner[];
              if (work.id && work.equipmentCode && work.trackingStatus === "WORKING") {
                const result = await putEquipmentCorrectionMutate({
                  equipmentsCorrectionPutRequest: {
                    // equipmentCode: work.equipmentCode
                    workId: work.id,
                  },
                });
                result.data.length
                  ? customAlert(
                      t("workMessage.correctionSuccess"),
                      t("workButton.correction"),
                      "info",
                    )
                  : customAlert(t("workMessage.correctionFail"), t("workButton.correction"), "red");
                actions.setWorks((prevWorks) => []);
              } else {
                customAlert(t("workMessage.correctionFail2"), t("workButton.correction"), "red");
              }
            }}
          >
            {t("workButton.correction")}
          </Work.Button>
          {useablePigmentInput && (
            <Work.Button
              variant="light"
              color="violet"
              disabled={!(state.works && state.works.length === 1)}
              onClick={() => {
                const [work] = state.works;
                openModal(
                  <PigmentInputForm workId={work.id} />,
                  {},
                  t("workButton.pigmentInput"),
                  true,
                ).then(() => {
                  refetch();
                  actions.setWorks((prevWorks) => []);
                });
              }}
            >
              {t("workButton.pigmentInput")}
            </Work.Button>
          )}
        </Work.LeftActionBar>
        <Work.RightActionBar>
          <Work.Button
            color="yellow"
            disabled={!(state.works && state.works.length >= 1)}
            onClick={() => {
              const works = state.works;
              openModal(
                <WorkUpdateForm<"plan">
                  workRowData={works as ProductionPlansGet200ResponseRowsInnerWorksInner[]}
                />,
                null,
                `${t("work.work")} ${t("workButton.updated")}`,
                true,
              ).then((result) => {
                if (result === true) {
                  actions.setWorks((prevWorks) => []);
                  actions.setTempDefects([]);
                  setUpdateSuccess(true);
                  refetch();
                }
              });
            }}
          >
            {t("workButton.updated")}
          </Work.Button>
          <Work.Button
            color="red"
            disabled={!(state.works && state.works.length >= 1)}
            onClick={() => {
              if (window.confirm(t("workMessage.delete"))) {
                try {
                  state.works.forEach((work) => {
                    work.id &&
                      deleteMutate(
                        { workId: work.id },
                        {
                          onSuccess: () => {
                            actions.setWorks((prevWorks) => []);
                            actions.setTempDefects([]);
                            setUpdateSuccess(true);
                            refetch();
                          },
                        },
                      );
                  });
                } catch (e) {
                  alert(t("workMessage.deleteError"));
                  console.error("Error deleting work: ", e);
                }
              }
            }}
          >
            {t("workButton.delete")}
          </Work.Button>
          {/* <Work.Button onClick={() => {
                        // doSomething
                        state.works.forEach(async (work) => {
                            await handleUpdateQuantity(work);
                            await handleUpdateDefects(work);
                        });
                    }}>모두 저장</Work.Button> */}
          <Work.Button
            disabled={!(state.works && state.works.length >= 1)}
            onClick={() => {
              state.works.forEach(async (work) => {
                await handleUpdateQuantity(work);
                await handleUpdateDefects(work);
              });
              refetch();
              // const [work] = state.works;
              // await handleUpdateQuantity(work);
              // await handleUpdateDefects(work);
            }}
          >
            {t("workButton.save")}
          </Work.Button>
        </Work.RightActionBar>
      </Work.WorkTableActionBar>
      <Work.WorkTableWrapper>
        <Work.WorkTable isWithBorder={true}>
          <Work.WorkTableHead
            pageKey="plan"
            defectTypes={defectTypes && defectTypes[1]}
            formReset={updateSuccess}
          />
          <Work.WorkTableBody>
            {/* WorkRow 영역 */}
            {works && works.length ? (
              works?.map((work, index) => {
                // 소켓 데이터를 해당 ID에 맞게 필터링
                const socketDataObject = socketData.find((data) => data.workId === work.id);

                return (
                  <WorkPassArea<"plan">
                    pageKey="plan"
                    socketData={socketDataObject}
                    rowData={work}
                    key={work.id || index}
                    defectTypes={defectTypes && defectTypes[1]}
                    formReset={updateSuccess}
                  />
                );
              })
            ) : (
              <WorkNotFound defectLength={defectTypes ? defectTypes[1].length : 0} />
            )}
          </Work.WorkTableBody>
        </Work.WorkTable>
      </Work.WorkTableWrapper>
      <Pagination
        onChange={(e) => {
          setPage(e);
          actions.setWorks(() => []);
          actions.setTempWorks(() => []);
        }}
        value={page}
        total={workData?.totalPages as number}
        size="lg"
        radius="sm"
        w="100%"
        position="center"
      />
    </WorkExampleContainer>
  );
};

const WorkExampleContainer = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
