import { customFunctions } from "@/config/customFunction";
import { itemType } from "@/constants/items";
import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { GradesAutoComplete } from "@/features/ui/autoComplete/grade/grades-autoComplete";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import { Checkbox, Flex, NumberInput, Select, Text, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ItemsGetRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

interface ItemsFormProps {
  formData: UseFormReturnType<Partial<ItemsGetRequest>>;
}

export const ItemsForm = ({ formData }: ItemsFormProps) => {
  const { t } = useTranslation();
  const lnItemType = itemType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  return (
    <Flex direction="column" gap="sm" p="md" w="100%">
      <Flex gap="xl" align="center">
        <Flex gap="md" align="center">
          <Text fw={700} fz="lg" color="red" w="1rem">
            *
          </Text>
          <Text fw={700} fz="lg" color="red" w="10rem">
            품목명
          </Text>
        </Flex>
        <TextInput placeholder="품목명" size="md" w="20rem" {...formData.getInputProps("name")} />
      </Flex>
      <Flex gap="xl" align="center">
        <Flex gap="md" align="center">
          <Text fw={700} fz="lg" color="red" w="1rem">
            *
          </Text>
          <Text fw={700} fz="lg" color="red" w="10rem">
            품목유형
          </Text>
        </Flex>
        <Select
          placeholder="품목유형을 선택해 주세요."
          data={lnItemType}
          size="md"
          w="20rem"
          {...formData.getInputProps("itemType")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Flex gap="md" align="center">
          <Text fw={700} fz="lg" color="red" w="1rem">
            *
          </Text>
          <Text fw={700} fz="lg" color="red" w="10rem">
            품목코드
          </Text>
        </Flex>
        <TextInput
          placeholder="코드를 입력해주세요."
          size="md"
          w="20rem"
          {...formData.getInputProps("code")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          품목분류
        </Text>
        <ItemCategoryAutoComplete
          placeholder="품목분류를 선택해 주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("itemCategoryName")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          품목군
        </Text>
        <AbstractItemAutoComplete
          placeholder="품목군을 선택해 주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("abstractItemCode")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          규격
        </Text>
        <TextInput
          placeholder="규격을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("spec")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          높이
        </Text>
        <NumberInput
          min={1}
          step={0.01}
          precision={2}
          placeholder="높이를 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("height")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          깊이
        </Text>
        <NumberInput
          min={1}
          step={0.01}
          precision={2}
          placeholder="깊이를 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("depth")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          폭
        </Text>
        <NumberInput
          min={1}
          step={0.01}
          precision={2}
          placeholder="폭을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("width")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          재질
        </Text>
        <TextInput
          placeholder="재질을 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("texture")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          기준 UPH
        </Text>
        <NumberInput
          placeholder="기준 UPH를 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("standardUph")}
        />
      </Flex>
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          단위
        </Text>
        <TextInput
          placeholder="단위를 입력해주세요."
          size="sm"
          w="20rem"
          {...formData.getInputProps("unitText")}
        />
      </Flex>
      {customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING && (
        <Flex gap="xl" align="center">
          <Text fw={700} c="dimmed" w="12rem">
            등급
          </Text>
          <GradesAutoComplete
            placeholder="등급을 선택해 주세요."
            size="sm"
            w="20rem"
            {...formData.getInputProps("gradeCode")}
          />
        </Flex>
      )}
      <Flex gap="xl" align="center">
        <Text fw={700} c="dimmed" w="12rem">
          재고 LOT 관리 기준
        </Text>
        <Flex justify="space-between" align="center" gap="xl">
          <Checkbox label="이름" {...formData.getInputProps("usesLotName")} />
          <Checkbox label="유효기한" {...formData.getInputProps("usesLotExpiration")} />
        </Flex>
      </Flex>
    </Flex>
  );
};
