import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { OperationAutoComplete } from "@/features/ui/autoComplete/operation/operation-autoComplete";
import { EquipmentsMultiAutoComplete } from "@/features/ui/multiAutoComplete/equipment/equipment-multiAutoComplete"

import { consumeType, routingType } from "@/constants/routings";
import { useModal } from "@/features/modal/ModalStackManager";
// import i18n from "@/language/i18n";
import { mutateRoutings } from "@/api/routing/useRoutingQuery";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import {
  DefaultApiRoutingsPostRequest,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface RoutingCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}
export const RoutingCreateForm = (params: RoutingCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { t } = useTranslation();
  const lnRoutingType = routingType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const lnConsumeType = consumeType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: postMutate } = useMutation(
    (params: DefaultApiRoutingsPostRequest) =>
      mutateRoutings.create(params).mutationFn(params as DefaultApiRoutingsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["routings"]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("routing.routing")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("routing.code") }),
          `${t("common.createFail", { item: `${t("routing.routing")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      itemCode: undefined,
      code: code ?? undefined,
      routingType: undefined,
      moldCode: undefined,
      consumeType: undefined,
      operationCode: undefined,
      seq: undefined,
      equipmentCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate({
        routingsGetRequest: {
          itemCode: form.values.itemCode as any,
          code: code ?? (form.values.code as any),
          routingType: form.values.routingType as any,
          moldCode: form.values.moldCode as any,
          consumeType: form.values.consumeType as any,
          operationCode: form.values.operationCode as any,
          seq: form.values.seq as any,
          equipmentCode: form.values.equipmentCode as any,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("routing.code") }),
        `${t("common.createFail", { item: `${t("routing.routing")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <ItemAutoComplete
            withAsterisk
            label={t("item.code")}
            placeholder={t("placeholder.input", { item: `${t("item.code")}` })}
            {...form.getInputProps("itemCode")}
            getItemType={[ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT]}
          />
          <OperationAutoComplete
            withAsterisk
            label={t("operation.code")}
            placeholder={t("placeholder.select", { item: `${t("operation.code")}` })}
            {...form.getInputProps("operationCode")}
          />
          <TextInput
            withAsterisk
            label={t("routing.code")}
            placeholder={t("placeholder.input", { item: `${t("routing.code")}` })}
            {...form.getInputProps("code")}
          />
          <Select
            withAsterisk
            label={t("routing.type")}
            placeholder={t("placeholder.select", { item: `${t("routing.type")}` })}
            data={lnRoutingType}
            {...form.getInputProps("routingType")}
          />
          <MoldAutoComplete
            label={t("mold.code")}
            placeholder={t("placeholder.select", { item: `${t("mold.code")}` })}
            {...form.getInputProps("moldCode")}
          />
          <Select
            withAsterisk
            placeholder={t("placeholder.select", { item: `${t("routing.consumeType")}` })}
            label={t("routing.consumeType")}
            data={lnConsumeType}
            {...form.getInputProps("consumeType")}
          />
          <TextInput
            withAsterisk
            label={t("routing.seq")}
            placeholder={t("placeholder.input", { item: `${t("routing.req")}` })}
            {...form.getInputProps("seq")}
          />
          <EquipmentsMultiAutoComplete
            label="설비 코드"
            {...form.getInputProps("equipmentCode")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "routing",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              {t("common.create", { item: `${t("routing.routing")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
