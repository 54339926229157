import { mutateTransportation } from "@/api/transportation/useTransportationQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Main } from "@/features/standard/Main";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Button, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  ScmApiTransportationTransportationIdPutRequest,
  TransportationGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Update = Object.assign({}, Main, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  DateInput: DateInput,
});

interface TransportationStatusFormProps {
  formatterProps: TransportationGet200ResponseRowsInner | undefined;
}

export const TransportationStatusForm = (params: TransportationStatusFormProps) => {
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: ScmApiTransportationTransportationIdPutRequest) =>
      mutateTransportation
        .update(params)
        .mutationFn(params as ScmApiTransportationTransportationIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["transportation"]);
        closeModal(formatterProps);
        customAlert("배차 상태가 성공적으로 수정되었습니다.", "배차 상태 수정 성공", "green");
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: "배차 상태" }),
          "배차 상태 수정 실패",
          "red",
        );
      },
    },
  );

  const { closeModal } = useModal();

  const form = useForm({
    initialValues: {
      partnerCode: formatterProps?.partnerCode,
      status: formatterProps?.status,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate({
        transportationId: formatterProps?.id ?? 0,
        transportationGetRequest: {
          direction: formatterProps?.direction!,
          partnerCode: form.values.partnerCode!,
          status: form.values.status!,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: "배차 상태" }),
        "배차 상태 수정 실패",
        "red",
      );
    }
  };

  return (
    <>
      <Update.Wrapper>
        <Select
          label="배차상태"
          placeholder="배차상태"
          data={[
            { label: "예약확인중", value: "SCHEDULED" },
            { label: "지연중", value: "LOADING" },
            { label: "완료", value: "DONE" },
            { label: "운행중", value: "ON_ROAD" },
            { label: "사고", value: "ERROR" },
          ]}
          defaultValue={formatterProps?.status}
          {...form.getInputProps("status")}
          error={form.isDirty("status") ? "저장 필요" : ""}
          styles={{
            input: {
              color: form.isDirty("status") ? "cadetblue !important" : "inherit",
              borderColor: form.isDirty("status") ? "cadetblue !important" : "inherit",
            },
            error: { fontSize: 11, color: "cadetblue" },
          }}
          withinPortal
        />
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={() => closeModal(null)}>
            취소
          </Update.Button>
          <Update.Button onClick={onSubmit}>배차 상태 변경</Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
