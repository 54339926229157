import { mutateRoutingBoms } from "@/api/routingBoms/useRoutingBomsQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { Box, Button, Group, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner,
  DefaultApiRoutingBomsRoutingBomIdPutRequest,
  ItemsGet200ResponseRowsInnerItemTypeEnum,
  RoutingBomsGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const BomByOperationUpdateWrap = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  ButtonBox: FormButtonBox,
});

type BomByOperationUpdateFormProps = {
  data: AuthSignupPost201ResponseEquipmentWorksInnerRoutingDataRoutingBomsInner;
};

export const BomByOperationUpdateForm = ({ data }: BomByOperationUpdateFormProps) => {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();
  const { t } = useTranslation();

  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiRoutingBomsRoutingBomIdPutRequest) =>
      mutateRoutingBoms.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customAlert(
          `${t("message.editSuccess", { item: `${t("item.bom")}` })}`,
          t("common.editSuccess"),
          "green",
        );
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("item.bom") }),
          `${t("common.editFail", { item: `${t("item.bom")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm<RoutingBomsGetRequest>({
    initialValues: {
      itemCode: data.itemCode as string,
      seq: data.seq,
      quantityNumerator: data.quantityNumerator,
      description: data.description as string,
      routingCode: data.routingCode as string,
    },
    validate: {
      itemCode: (value) => {
        if (!value) {
          return `${t("placeholder.input", { item: `${t("item.code")}` })}`;
        }
      },
      seq: (value) => {
        if (!value) {
          return `${t("placeholder.input", { item: `${t("routing.sequence")}` })}`;
        }
      },
      quantityNumerator: (value) => {
        if (!value) {
          return `${t("placeholder.input", { item: `${t("inventory.quantity")}` })}`;
        }
      },
    },
  });

  const onSubmit = (values: RoutingBomsGetRequest) => {
    updateMutate(
      {
        routingBomId: data.id as number,
        routingBomsGetRequest: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["items"]);
          closeModal({});
        },
      },
    );
  };

  return (
    <Box>
      <form
        style={{
          width: "100%",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
        }}
        onSubmit={form.onSubmit((values) => onSubmit(values))}
      >
        <BomByOperationUpdateWrap.Input
          label={t("routing.sequence")}
          {...form.getInputProps("seq")}
        />
        <ItemAutoComplete
          getItemType={[
            ItemsGet200ResponseRowsInnerItemTypeEnum.MATERIAL,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT,
            ItemsGet200ResponseRowsInnerItemTypeEnum.SUBSIDIARY,
          ]}
          label={t("item.code")}
          {...form.getInputProps("itemCode")}
        />
        <BomByOperationUpdateWrap.Input
          label={t("inventory.quantity")}
          {...form.getInputProps("quantity")}
        />
        {customFunctions.ADD_ROUTING_BOM_DESCRIPTION && (
          <BomByOperationUpdateWrap.Input
            label={t("routing.descript")}
            {...form.getInputProps("description")}
          />
        )}
        <Group position="right" mt="md">
          <BomByOperationUpdateWrap.Button type="submit" color="yellow">
            {t("common.edit", { item: `${t("item.bom")}` })}
          </BomByOperationUpdateWrap.Button>
        </Group>
      </form>
    </Box>
  );
};
