// @fileoverview : 기준정보 > 설비

import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { EquipmentCreateForm } from "@/features/equipment/components/form/EquipmentCreateForm";
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { EquipmentUpdateForm } from "@/features/equipment/components/form/EquipmentUpdateForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { css } from "@emotion/css";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import {
  AuthSignupPost201ResponseEquipment,
  EquipmentsGet200Response,
  MasterApiEquipmentsEquipmentIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const Equipment = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { t } = useTranslation();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiEquipmentsEquipmentIdDeleteRequest) =>
      mutateEquipment
        .delete(params)
        .mutationFn(params as MasterApiEquipmentsEquipmentIdDeleteRequest | any),
    {
      onSuccess: () => {
        customAlert(
          `${t("message.deleteSuccess", { item: `${t("equipment.equipment")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
        refetch();
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("equipment.code") }),
          `${t("common.deleteFail", { item: `${t("equipment.equipment")}` })}`,
          "red",
        );
      },
    },
  );

  const columns: readonly Column<AuthSignupPost201ResponseEquipment>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "siteCode",
        name: t("site.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(<SiteDetailForm siteCode={formatterProps.row.siteCode} />, null, "")
              }
            >
              {formatterProps.row.siteCode}
            </LinkAnchor>
          );
        },
      },
      {
        key: "code",
        name: t("equipment.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(<EquipmentDetailForm equipmentCode={formatterProps.row.code} />, null, "")
              }
            >
              {formatterProps.row.code}
            </LinkAnchor>
          );
        },
      },
      {
        key: "name",
        name: t("equipment.name"),
        sortable: true,
        resizable: true,
      },
      {
        key: "equipmentType",
        name: t("equipment.type"),
        sortable: true,
        resizable: true,
      },
      {
        key: "volt",
        name: t("equipment.volt"),
        sortable: true,
        resizable: true,
        cellClass: css`
          justify-content: flex-end;
        `,
      },
      {
        key: "fromLocationName",
        name: t("equipment.fromLocation"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={formatterProps.row.fromLocationCode} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.fromLocationName}
            </LinkAnchor>
          );
        },
      },
      {
        key: "toLocationName",
        name: t("equipment.toLocation"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={formatterProps.row.toLocationCode} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.toLocationName}
            </LinkAnchor>
          );
        },
      },
      {
        key: "targetLocationName",
        name: t("equipment.targetLocation"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={formatterProps.row.targetLocationCode} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.targetLocationName}
            </LinkAnchor>
          );
        },
      },
      {
        key: "downtimeReasonCode",
        name: t("equipment.downtimeReason"),
        sortable: true,
        resizable: true,
        cellClass: css`
          justify-content: flex-end;
        `,
        formatter: (formatterProps) => {
          return <div>{formatterProps.row.downtimeReasonCode?.join(", ")}</div>;
        },
      },
      {
        key: "options",
        name: t("common.action"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const updateEquipmentActionView = () => {
            openModal(
              <EquipmentUpdateForm formatterProps={formatterProps?.row} />,
              null,
              `${t("common.edit", { item: `${t("equipment.equipment")}` })}`,
              true,
            );
          };

          const deleteEquipmentAction = () => {
            openModal(
              <ConfirmForm
                message={t("message.rowDelete")}
                yesCallback={() => deleteMutate({ equipmentId: formatterProps?.row.id })}
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              `${t("common.delete", { item: `${t("equipment.equipment")}` })}`,
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={updateEquipmentActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deleteEquipmentAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [deleteMutate, openModal, closeModal, t],
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("equipment.equipment")}` })}`,
        `${t("common.deleteFail", { item: `${t("equipment.equipment")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ equipmentId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${t("equipment.equipment")}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader
          createFn={() =>
            openModal(
              <EquipmentCreateForm />,
              null,
              `${t("common.additional", { item: `${t("equipment.equipment")}` })}`,
              true,
            )
          }
          createDataTitle={t("equipment.equipment")}
          deleteFn={() => handleDelete()}
        />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${t("equipment.equipment")}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${t("equipment.equipment")}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<EquipmentsGet200Response, AuthSignupPost201ResponseEquipment>
            columns={columns}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<EquipmentsGet200Response> />
      </Flex>
    </Flex>
  );
};
