import { mutateOperations } from "@/api/operations/useOperationsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiOperationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface OperationsCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export function OperationsCreateForm(params: OperationsCreateFormProps) {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: postMutate } = useMutation(
    (params: DefaultApiOperationsPostRequest) =>
      mutateOperations.create(params).mutationFn(params as DefaultApiOperationsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["operations"]);
        closeModal(form.values);
        customAlert(
          `${t("message.createSuccess", { item: `${t("operation.operation")}` })}`,
          t("common.createSuccess"),
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("operation.code") }),
          `${t("common.createFail", { item: `${t("operation.operation")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: code ?? "",
      name: "",
      siteCode: "",
      fromLocationCode: "",
      toLocationCode: "",
    },
  });

  const onSubmit = async () => {
    try {
      postMutate({
        operationsGetRequest: {
          code: code ?? form.values.code,
          name: form.values.name,
          siteCode: form.values.siteCode,
          fromLocationCode: form.values.fromLocationCode,
          toLocationCode: form.values.toLocationCode,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("operation.code") }),
        `${t("common.createFail", { item: `${t("operation.operation")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label={t("operation.code")}
            placeholder={t("placeholder.input", { item: `${t("operation.code")}` })}
            {...form.getInputProps("code")}
          />
          <TextInput
            withAsterisk
            label={"operation.name"}
            placeholder={t("placeholder.input", { item: `${t("operation.code")}` })}
            {...form.getInputProps("name")}
          />
          <SitesAutoComplete
            withAsterisk
            label={t("site.code")}
            // description="siteCode"
            width={"100%"}
            placeholder={t("placeholder.select", { item: `${t("site.code")}` })}
            {...form.getInputProps("siteCode")}
          />
          <LocationAutoComplete
            withAsterisk
            label={t("operation.fromLocationCode")}
            width={"100%"}
            placeholder={t("placeholder.select", { item: `${t("operation.fromLocation")}` })}
            {...form.getInputProps("fromLocationCode")}
          />
          <LocationAutoComplete
            withAsterisk
            label={t("operation.toLocationCode")}
            width={"100%"}
            placeholder={t("placeholder.select", { item: `${t("operation.toLocation")}` })}
            {...form.getInputProps("toLocationCode")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "operations",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              {t("common.create", { item: `${t("operation.operation")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
