import { t } from "i18next";

export const sorterMap: {
  key?: string;
  value: string;
  label: string;
}[] = [
        { key: "asc", value: "", label: t("common.ascendingOrder") },
        { key: "desc", value: "-", label: t("common.descendingOrder") },
    ];
