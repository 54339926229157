import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { Button, Group, Radio, TextInput } from "@mantine/core";

import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { customFunctions } from "@/config/customFunction";
import { mutateLocations } from "@/api/locations/useLocationsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiLocationsLocationIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const LocationUpdateForm = (params: { formatterProps: any }) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiLocationsLocationIdPutRequest) =>
      mutateLocations
        .update(params)
        .mutationFn(params as MasterApiLocationsLocationIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["locations"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("location.location")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("location.code") }),
          `${t("common.editFail", { item: `${t("location.location")}` })}`,
          "red",
        );
      },
    },
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      siteCode: formatterProps.siteCode,
      isAvailable: formatterProps.isAvailable,
    },
  });

  const onSubmit = () => {
    try {
      updateMutate({
        locationId: formatterProps.id as number,
        locationsGetRequest: {
          code: form.values.code!,
          name: form.values.name!,
          siteCode: form.values.siteCode!,
          isAvailable: formatterProps.isAvailable,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("location.code") }),
        `${t("common.editFail", { item: `${t("location.location")}` })}`,
        "red",
      );
    }
  };

  return (
    <>
      <Update.Wrapper>
        <Update.Input
          label={t("location.code")}
          placeholder={t("placeholder.input", { item: `${t("location.code")}` })}
          withAsterisk
          defaultValue={formatterProps?.code}
          {...form.getInputProps("code")}
          error={form.isDirty("code") ? t("common.toSave") : errorMessage ? errorMessage : ""}
          styles={{
            input: {
              color: form.isDirty("code") ? "red !important" : "",
              borderColor: form.isDirty("code") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const regex = /^[A-Za-z0-9_]*$/;
            if (regex.test(e.target.value)) {
              form.getInputProps("code").onChange(e);
              setErrorMessage(""); // 유효한 입력인 경우 에러 메시지 제거
            } else {
              setErrorMessage(t("message.noKorean")); // 유효하지 않은 입력인 경우 에러 메시지 설정
            }
          }}
        />
        <Update.Input
          label={t("location.name")}
          placeholder={t("placeholder.input", { item: `${t("location.name")}` })}
          withAsterisk
          defaultValue={formatterProps?.name}
          {...form.getInputProps("name")}
          error={form.isDirty("name") ? t("common.toSave") : ""}
          styles={{
            input: {
              color: form.isDirty("name") ? "red !important" : "",
              borderColor: form.isDirty("name") ? "red !important" : "",
            },
            error: { fontSize: 11, color: "red" },
          }}
        />
        <SitesAutoComplete
          label={t("site.code")}
          placeholder={t("placeholder.select", { item: `${t("site.code")}` })}
          withAsterisk
          width="100%"
          defaultValue={formatterProps?.siteCode}
          {...form.getInputProps("siteCode")}
          error={form.isDirty("siteCode") ? t("common.toSave") : ""}
        />
        {customFunctions.ADD_DEFECT_LOCATION && (
          <Radio.Group
            // {...form.getInputProps("isAvailable")}
            defaultValue={formatterProps?.isAvailable ? "true" : "false"}
            name="가용 창고 여부"
            label="가용 창고 여부"
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="true" label="가용" />
              <Radio value="false" label="비가용" />
            </Group>
          </Radio.Group>
        )}
        <Update.ButtonBox>
          <Update.Button color="gray" onClick={closeModal}>
            {t("common.cancel")}
          </Update.Button>
          <Update.Button
            disabled={validateFormValues({
              pageType: "location",
              values: form.values,
            })}
            onClick={onSubmit}
          >
            {t("common.edit", { item: `${t("location.location")}` })}
          </Update.Button>
        </Update.ButtonBox>
      </Update.Wrapper>
    </>
  );
};
