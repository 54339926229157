import { inventories } from "@/api/inventories/useInventoriesQuery";
import { setDateFormatStringWithTime } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import { Checkbox, Pagination, Table, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface WorkProductionInventoryProps {
  lstItemCode: (string | undefined)[];
  locationCode?: string;
}

export const WorkProductionInventory = ({
  lstItemCode,
  locationCode,
}: WorkProductionInventoryProps) => {
  const [activePage, setActivePage] = useState<number>(1);
  const { t } = useTranslation();

  // TODO: 데이터 바뀌면 재적용 필요
  // const { data: productionInventory } = useInventoriesGetQuery({
  //     query: {
  //         $and: [
  //             { itemCode: lstItemCode },
  //             { locationCode: locationCode },
  //             { quantity: { $ne: 0 }},
  //         ],
  //     },
  //     activePage: activePage,
  // });
  const { data: productionInventory } = useQuery(
    inventories.get({
      query: {
        $and: [{ itemCode: lstItemCode }, { locationCode: locationCode }, { quantity: { $ne: 0 } }],
      },
      page: activePage,
    }),
  );

  const rows: any[] = productionInventory?.data?.rows ?? [];

  return (
    <WorkTabBodyContainer>
      <TabBodyTitle>
        <Text fz="xl" fw={500}>
          {t("workTab.productionInventory")}
        </Text>
        <Text fz="xs" fw={400}>
          {t("productionInventory.description")}
        </Text>
      </TabBodyTitle>
      <TabBodyTable>
        <Thead>
          <Tr>
            <Th width={100}>{t("routing.sequence")}</Th>
            <Th width={180}>{t("inputLot.material")}</Th>
            <Th width={200}>{t("inputLot.materialLot")}</Th>
            <Th width={200}>{t("inputLot.inputLot")}</Th>
            <Th width={200}>{t("inputLot.createdAt")}</Th>
            <Th>{t("inventory.quantity")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.length ? (
            rows.map((inputLot) => (
              <Tr>
                <Td width={100} style={{ justifyContent: "flex-end" }}>
                  {inputLot.id}
                </Td>
                {/* TODO: mantine UI -> NavLink 적용 필요 */}
                <Td width={180}>{inputLot.lotName}</Td>
                <Td width={200}>
                  <Checkbox
                    size="xs"
                    mr={12}
                    label={t("lot.name")}
                    defaultChecked={inputLot.itemUsesLotName}
                    disabled
                  />
                  <Checkbox
                    size="xs"
                    label={t("lot.expiration")}
                    defaultChecked={inputLot.itemUsesLotExpiration}
                    disabled
                  />
                </Td>
                <Td width={200}>[LOT] id: {inputLot.lotId}</Td>
                <Td width={200}>{setDateFormatStringWithTime(inputLot.lotCreatedAt)}</Td>
                <Td style={{ justifyContent: "flex-end" }}>
                  {setToLocaleString(inputLot.quantity)} {inputLot?.unitText}
                </Td>
              </Tr>
            ))
          ) : (
            <NoDataTr>
              <Text>{t("productionInventory.notFound")}</Text>
            </NoDataTr>
          )}
        </Tbody>
      </TabBodyTable>
      <PaginationWrapper>
        <Pagination
          onChange={setActivePage}
          value={activePage}
          total={productionInventory?.data?.totalPages ?? 0}
          size="lg"
          radius="sm"
        />
      </PaginationWrapper>
    </WorkTabBodyContainer>
  );
};

const WorkTabBodyContainer = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 0.5rem;
`;

const TabBodyTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const TabBodyTable = styled(Table)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Tr = styled.tr`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
  flex: ${(props) => (props.width ? `0 0 auto` : `1 0 0`)};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
  flex: ${(props) => (props.width ? `0 0 auto` : `1 0 0`)};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

// const LeftSpace = styled.span`
//   width: 32px;
//   align-self: stretch;
//   background: ${theme.colors?.gray?.[3]};
//   border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
//   opacity: 0.2;
// `;

// const RightSpace = styled.span`
//   flex: 1 0 0;
//   align-self: stretch;
//   background: ${theme.colors?.gray?.[3]};
//   border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
//   opacity: 0.2;
// `;

const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const NoDataTr = styled.tr`
  display: flex;
  width: 100%;
  height: 4rem;
  justify-content: center;
  align-items: center;
`;
