import { mutateUsers } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { DepartmentAutoComplete } from "@/features/ui/autoComplete/department/department-autoComplete";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiUsersUserIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

const isDepartmentInformIncluded = customFunctions.ADD_USER_DEPARTMENT_INFORM;

export function UsersUpdateForm(params: { formatterProps: any }) {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      mutateUsers.update(params).mutationFn(params as MasterApiUsersUserIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
        closeModal(formatterProps);
        customAlert(
          `${t("message.editSuccess", { item: `${t("user.user")}` })}`,
          `${t("common.editSuccess")}`,
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: t("user.code") }),
          `${t("common.editFail", { item: `${t("user.user")}` })}`,
          "red",
        );
      },
    },
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      username: formatterProps.username,
      password: formatterProps.password,
      equipmentCode: formatterProps.equipmentCode,
      printerCode: formatterProps.printerCode,
      locationCode: formatterProps.locationCode,
      userType: formatterProps.userType,
      ...(isDepartmentInformIncluded ? { departmentCode: formatterProps.departmentCode } : {}),
    },
  });

  const onSubmit = () => {
    try {
      updateMutate({
        userId: formatterProps.id as number,
        usersUserIdDeleteRequest: {
          code: form.values.code,
          name: form.values.name,
          username: form.values.username,
          password: form.values.password,
          equipmentCode: form.values.equipmentCode,
          printerCode: form.values.printerCode,
          locationCode: form.values.locationCode,
          departmentCode: form.values.departmentCode,
          userType: form.values.userType,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: t("user.code") }),
        `${t("common.editFail", { item: `${t("user.user")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label={t("user.code")}
            placeholder={t("placeholder.input", { item: `${t("user.code")}` })}
            withAsterisk
            {...form.getInputProps("code")}
            defaultValue={formatterProps?.code}
            error={form.isDirty("code") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("code") ? "red !important" : "",
                borderColor: form.isDirty("code") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            label={t("user.name")}
            placeholder={t("placeholder.input", { item: `${t("user.name")}` })}
            withAsterisk
            {...form.getInputProps("name")}
            defaultValue={formatterProps?.name}
            error={form.isDirty("name") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("name") ? "red !important" : "",
                borderColor: form.isDirty("name") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            label={t("user.username")}
            placeholder={t("placeholder.input", { item: `${t("user.username")}` })}
            withAsterisk
            {...form.getInputProps("username")}
            defaultValue={formatterProps?.username}
            error={form.isDirty("username") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("username") ? "red !important" : "",
                borderColor: form.isDirty("username") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <TextInput
            // type="number"
            label={t("user.password")}
            placeholder={t("placeholder.input", { item: `${t("user.password")}` })}
            // placeholder="password"
            {...form.getInputProps("password")}
            defaultValue={formatterProps?.password}
            error={form.isDirty("password") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("password") ? "red !important" : "",
                borderColor: form.isDirty("password") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          <EquipmentAutoComplete
            label={t("equipment.code")}
            placeholder={t("placeholder.select", { item: `${t("equipment.code")}` })}
            {...form.getInputProps("equipmentCode")}
            defaultValue={formatterProps?.equipmentCode}
            error={form.isDirty("equipmentCode") ? t("common.toSave") : ""}
          />
          <LocationAutoComplete
            label={t("location.code")}
            placeholder={t("placeholder.select", { item: `${t("location.code")}` })}
            {...form.getInputProps("locationCode")}
            defaultValue={formatterProps?.locationCode}
            error={form.isDirty("locationCode") ? t("common.toSave") : ""}
          />
          {isDepartmentInformIncluded && (
            <DepartmentAutoComplete
              label={t("department.code")}
              placeholder={t("placeholder.select", { item: `${t("department.code")}` })}
              {...form.getInputProps("departmentCode")}
              defaultValue={formatterProps?.departmentCode}
              error={form.isDirty("departmentCode") ? t("common.toSave") : ""}
            />
          )}
          <TextInput
            label={t("user.printer")}
            {...form.getInputProps("printerCode")}
            placeholder={t("placeholder.input", { item: `${t("user.printer")}` })}
            defaultValue={formatterProps?.printerCode}
            error={form.isDirty("printerCode") ? t("common.toSave") : ""}
            styles={{
              input: {
                color: form.isDirty("printerCode") ? "red !important" : "",
                borderColor: form.isDirty("printerCode") ? "red !important" : "",
              },
              error: { fontSize: 11, color: "red" },
            }}
          />
          {customFunctions.ADD_USER_TYPE && (
            <Select
              label={t("user.userType")}
              data={[
                { value: "FIELD_WORKER", label: "현장 근무자" },
                { value: "MANAGER", label: "관리자" },
                { value: "OFFICE_WORKER", label: "사무직 근무자" },
                { value: "EQUIPMENT_ASSIGNER", label: "장비 지정자" },
              ]}
              {...form.getInputProps("userType")}
              value={form.values.userType}
            />
          )}
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "users",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              {t("common.edit", { item: `${t("user.user")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
