import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { OrderItemIncomingFormProps } from "@/features/order/form/orderItemsIncomingForm";
import customAlert from "@/features/ui/alert/alert";
import { DefaultApiWmsPurchaseOrderStockSerialPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";

export interface OrderItemsIncomingProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

interface OrderItemsSubmitProps {
  item: OrderItemIncomingFormProps;
  targetLocationCode: string | undefined;
}

export const useOrderItemsIncoming = (params: OrderItemsIncomingProps) => {
  const queryClient = useQueryClient();
  const { setLoading } = params;
  const { mutate: incomingMutate, isLoading } = useMutation(
    (params: DefaultApiWmsPurchaseOrderStockSerialPostRequest) =>
      mutateInventories.purchaseOrderStockSerial(params).mutationFn(undefined),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["inventories"]);
        queryClient.invalidateQueries(["purchaseOrderItems"]);
        queryClient.invalidateQueries(["purchaseOrders"]);
        customAlert("입고에 성공하였습니다.", "입고 성공", "green");
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(error.response?.data.message ?? "입고에 실패하였습니다.", "입고 실패", "red");
      },
      onSettled: () => {
        setLoading(false);
      },
    },
  );

  const orderItemsSubmit = ({ item, targetLocationCode }: OrderItemsSubmitProps) => {
    incomingMutate({
      wmsPurchaseOrderStockSerialPostRequest: {
        itemCode: item.itemCode ?? "",
        locationCode: targetLocationCode ?? "",
        quantity: item?.inputValue?.toString() ?? item.inputEligibleQuantity?.toString() ?? "",
        lotInfo: item.lotInfo as any,
        num: Number(item.purchaseOrderItemQty),
        actionFrom: "WEB",
      },
    });
  };

  return {
    orderItemsSubmit,
    setLoading,
    isLoading,
  };
};
