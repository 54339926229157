import { customFunctions } from "@/config/customFunction";
import { ExtractChildrenType } from "@/features/productionPlan/form/CreateForm";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import { Paper, Space, Table, Text, Title } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ProductionPlansWithWorksPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

interface ProductionPlanCreateInfoFormProps {
  form: UseFormReturnType<
    Partial<ProductionPlansWithWorksPostRequest> & {
      scheduledAtDate: Date;
      siteCode?: string | undefined;
      siteName?: string | undefined;
      children?: ExtractChildrenType[];
    }
  >;
}

export const ProductionPlanCreateInfoForm = (params: ProductionPlanCreateInfoFormProps) => {
  const { form } = params;

  const renderProductionPlanMain = () => {
    return (
      <tr>
        <td>
          <Text>{dayjs(form.values.scheduledAt).format("YYYY-MM-DD")}</Text>
        </td>
        <td>
          <Text>{form.values.siteName}</Text>
        </td>
        <td>
          <Text>{form.values.itemCode}</Text>
        </td>
        <td>
          <Text ta="end">{setToLocaleString(form.values.targetQuantity)}</Text>
        </td>
      </tr>
    );
  }

  const renderProductionPlanChild = () => {
    if (customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN && Array.isArray(form?.values?.children)) {
      return form?.values?.children?.flatMap((child, i) => {
        return child?.works?.map((work, j) => {
          return (
            <tr key={i}>
              <td>
                <Text>{dayjs(form.values.scheduledAt).format("YYYY-MM-DD")}</Text>
              </td>
              <td>
                <Text>{form.values.siteName}</Text>
              </td>
              <td>
                <Text>{child.itemCode}</Text>
              </td>
              <td>
                <Text ta="end">{setToLocaleString(child.targetQuantity)}</Text>
              </td>
            </tr>
          );
        });
      })
    };

    return null
  }

  const renderWorkOrderMain = () => {

    if (Array.isArray(form.values.works)) {
      return form.values.works?.flatMap((work, i) => {
        return (
          <tr key={i}>
            <Td width={15}>
              <Text>{dayjs(work.scheduledAt).format("YYYY-MM-DD")}</Text>
            </Td>
            <Td width={10}>
              <Text>{form.values.itemCode}</Text>
            </Td>
            <Td width={10}>
              <Text>{work.routingCode}</Text>
            </Td>
            <Td width={20}>
              <Text>{work.equipmentCode}</Text>
            </Td>
            <Td width={20}>
              <Text>{work.routingOutsourceName}</Text>
            </Td>
            {customFunctions.ADD_DEFECT_LOCATION && (
              <Td width={15}>
                <Text>{work.defectLocationCode}</Text>
              </Td>
            )}
            {customFunctions.ADD_SETTING_PACKAGING_MACHINE && (
              <Th width={15}>
                <Text>{`${work.kgPerCount ?? 0} kg`}</Text>
              </Th>
            )}
            <Td width={10}>
              <Text ta="end">{setToLocaleString(work.targetQuantity)}</Text>
            </Td>
          </tr>
        );
      })
    }

    return null;

  }

  const renderWorkOrderChild = () => {
    if (customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN && Array.isArray(form?.values?.children)) {
      return form?.values?.children?.flatMap((child, i) => {
        return child?.works?.map((work, j) => {
          return (
            <tr key={i}>
              <Td width={15}>
                <Text>{dayjs(work.scheduledAt).format("YYYY-MM-DD")}</Text>
              </Td>
              <Td width={10}>
                <Text>{child.itemCode}</Text>
              </Td>
              <Td width={10}>
                <Text>{work.routingCode}</Text>
              </Td>
              <Td width={20}>
                <Text>{work.equipmentCode}</Text>
              </Td>
              <Td width={20}>
                <Text>{work.routingOutsourceName}</Text>
              </Td>
              {customFunctions.ADD_DEFECT_LOCATION && (
                <Td width={15}>
                  <Text>{work.defectLocationCode}</Text>
                </Td>
              )}
              {customFunctions.ADD_SETTING_PACKAGING_MACHINE && (
                <Th width={15}>
                  <Text>{`${work.kgPerCount ?? 0} kg`}</Text>
                </Th>
              )}
              <Td width={10}>
                <Text ta="end">{setToLocaleString(work.targetQuantity)}</Text>
              </Td>
            </tr>
          );
        });
      })
    }

    return null;
  }

  const renderOrder = customFunctions.ADD_PROCESS_BASED_PRODUCTION_PLAN_SORT_ASC ? [2, 1] : [1, 2];

  const renderProductionPlan = renderOrder.map((order) => {
    switch (order) {
      case 1:
        return <>{renderProductionPlanMain()}</>;
      case 2:
        return <>{renderProductionPlanChild()}</>;
      default:
        return null;
    }
  });

  const renderWorkOrder = renderOrder.map((order) => {
    switch (order) {
      case 1:
        return <>{renderWorkOrderMain()}</>;
      case 2:
        return <>{renderWorkOrderChild()}</>;
      default:
        return null;
    }
  });

  return (
    <>
      <Paper p="md" withBorder>
        <Title order={3}>기본 생산계획</Title>
        <Title order={5}>예상되는 생산 계획</Title>
        <Table>
          <thead>
            <tr>
              <th>계획일자</th>
              <th>사업장명</th>
              <th>품목코드</th>
              <th>목표 수량</th>
            </tr>
          </thead>
          <tbody>
            {renderProductionPlan}
          </tbody>
        </Table>
        <Space h="md" />
        <Title order={5}>예상되는 작업 지시</Title>
        <Table>
          <thead>
            <tr>
              <Th width={15}>일자</Th>
              <Th width={10}>품목코드</Th>
              <Th width={10}>라우팅코드</Th>
              <Th width={20}>
                설비코드 <span style={{ color: "red" }}>*</span>
              </Th>
              <Th width={20}>외주처</Th>
              {customFunctions.ADD_DEFECT_LOCATION && <Th width={15}>불량 창고</Th>}
              {customFunctions.ADD_SETTING_PACKAGING_MACHINE && <Th width={15}>포장단위</Th>}
              <Th width={10}>목표수량</Th>
            </tr>
          </thead>
          <tbody>
            {renderWorkOrder}
          </tbody>
        </Table>
      </Paper>
      {Array.isArray(form.values.sub)
        ? form.values.sub.map((subData, i) => {
          return (
            <>
              <Space h="md" />
              <Paper p="md" withBorder>
                <Title order={3}>패밀리 금형 생산계획</Title>
                <Title order={5}>예상되는 생산 계획</Title>
                <Table>
                  <thead>
                    <tr>
                      <th>계획일자</th>
                      <th>사업장코드</th>
                      <th>품목코드</th>
                      {customFunctions.ADD_DEFECT_LOCATION && <th>불량 창고</th>}
                      <th>목표 수량</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Text>{dayjs(subData.scheduledAt).format("YYYY-MM-DD")}</Text>
                      </td>
                      <td>
                        <Text>{form.values.siteCode}</Text>
                      </td>
                      <td>
                        <Text>{subData.itemCode}</Text>
                      </td>
                      {customFunctions.ADD_DEFECT_LOCATION && (
                        <td>
                          <Text>{subData.defectLocationCode}</Text>
                        </td>
                      )}
                      <td>
                        <Text ta="end">{setToLocaleString(subData.targetQuantity)}</Text>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Space h="md" />
                <Title order={5}>예상되는 작업 지시</Title>
                <Table>
                  <thead>
                    <tr>
                      <Th width={20}>일자</Th>
                      <Th width={10}>품목코드</Th>
                      <Th width={10}>라우팅코드</Th>
                      <Th width={25}>
                        설비코드 <span style={{ color: "red" }}>*</span>
                      </Th>
                      <Th width={25}>외주처</Th>
                      <Th width={10}>목표수량</Th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(subData.works) &&
                      subData.works?.map((work, i) => {
                        return (
                          <tr key={i}>
                            <Td width={20}>
                              <Text>{dayjs(work.scheduledAt).format("YYYY-MM-DD")}</Text>
                            </Td>
                            <Td width={10}>
                              <Text>{subData.itemCode}</Text>
                            </Td>
                            <Td width={10}>
                              <Text>{work.routingCode}</Text>
                            </Td>
                            <Td width={25}>
                              <Text>{work.equipmentCode}</Text>
                            </Td>
                            <Td width={25}>
                              <Text>{work.routingOutsourceName}</Text>
                            </Td>
                            <Td width={10}>
                              <Text ta="end">{setToLocaleString(work.targetQuantity)}</Text>
                            </Td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Paper>
            </>
          );
        })
        : null}
    </>
  );
};

const Th = styled.th<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;

const Td = styled.td<{ width?: number }>`
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
`;
