// @fileoverview : 영업관리 > 수주현황

import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { users } from "@/api/users/useUsersQuery";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { PurchaseOrderCreateForm } from "@/features/purchaseOrder/form/createForm";
import { PurchaseOrderDetailForm } from "@/features/purchaseOrder/form/detailForm";
import { PurchaseOrderItemsOutgoingForm } from "@/features/purchaseOrder/form/purchaseOrderItemsOutgoingForm";
import { PurchaseOrderUpdateForm } from "@/features/purchaseOrder/form/updateForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { handleErrorResponse } from "@/utils/errorMessage";
import { purchaseOrderStatusColor, setPurchaseOrderStatus } from "@/utils/scmDataStatus";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Avatar, Flex, Tooltip, Text, ActionIcon, Button, Badge } from "@mantine/core";
import {
  PurchaseOrdersGet200Response,
  PurchaseOrdersGet200ResponseRowsInner,
  ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconPlus, IconRecycle, IconTrash } from "@tabler/icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const PurchaseOrderPage = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery(users.who({}));
  const userCode = userData?.data.code;
  const orderTitle = userCode === "PB" ? t("order.order") : t("purchaseOrder.purchaseOrder"); // TODO: 다국어 처리

  const { openModal, closeModal } = useModal();
  const { query, setQuery, selectedRows, refetch, resetQueryStrings } = useStandardLayout();

  useEffect(() => {
    if (query.length === 0) {
      // query가 비어 있을 때만 실행
      setQuery([
        { direction: { $eq: "INBOUND" } },
        {
          $or: [
            { creatorUserCode: userCode },
            { creatorUserCode: "PB" },
            { partnerCode: userCode },
          ],
        },
      ]);
    }
  }, [query, userCode]); // 의존성 배열 유지

  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
      mutatePurchaseOrders
        .delete(params)
        .mutationFn(params as ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${t("message.deleteSuccess", { item: `${orderTitle}` })}`,
          `${t("common.deleteSuccess")}`,
          "green",
        );
      },
      onError: (error) => {
        customAlert(
          handleErrorResponse({ error, errorContext: `${orderTitle}${t("purchaseOrder.id")}` }),
          `${t("common.deleteFail", { item: `${orderTitle}` })}`,
          "red",
        );
      },
    },
  );

  // userCode가 PB일 경우 수발주를 나눈 이유는 협력사, 고객사 관련 컬럼이 사용자에 존재하지 않기 때문이다.
  // 하지만 특정한 경우에 수주 발주가 있음을 화면에 표시해 주어야 하기 때문에 구분을 위해 PB를 납품할 회사로 설정한 것.
  const columns: readonly Column<PurchaseOrdersGet200ResponseRowsInner>[] = useMemo(
    () => [
      {
        ...SelectColumn,
        resizable: true,
      },
      {
        key: "id",
        name: `${orderTitle}번호`,
        sortable: true,
        resizable: true,
      },
      {
        key: "code",
        name: t("operation.code"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <PurchaseOrderDetailForm purchaseOrderId={formatterProps.row.id as number} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.code}
            </LinkAnchor>
          );
        },
      },
      {
        key: "status",
        name: `${orderTitle} 상태`,
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <Badge
              variant="filled"
              color={
                purchaseOrderStatusColor[
                  formatterProps.row.status as keyof typeof purchaseOrderStatusColor
                ]
              }
            >
              {setPurchaseOrderStatus(formatterProps.row.status ?? "")}
            </Badge>
          );
        },
      },
      {
        key: "partner.name",
        name: "협력사명",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <PartnerDetailForm partnerCode={formatterProps.row.partner?.code} />,
                  null,
                  "",
                )
              }
            >
              {formatterProps.row.partner?.name}
            </LinkAnchor>
          );
        },
      },
      {
        key: "manager",
        name: "담당자",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <UsersDetailForm UserCode={formatterProps.row?.userCode} />,
                  null,
                  "사용자 상세",
                )
              }
            >
              {formatterProps.row.manager}
            </LinkAnchor>
          );
        },
      },
      {
        key: "numberOfPurchaseOrderItems",
        name: `${orderTitle}항목 갯수`, // TODO: 다국어 처리
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <Flex justify="flex-end">
              {setToLocaleString(formatterProps.row.numberOfPurchaseOrderItems)}
            </Flex>
          );
        },
      },
      {
        key: "sourceLocationName",
        name: "출고 로케이션",
        sortable: true,
        resizable: true,
        formatter: (formatterProps: any) => {
          return (
            <LinkAnchor
              onClick={() =>
                openModal(
                  <LocationDetailForm locationCode={formatterProps.row.sourceLocationCode} />,
                  null,
                  "로케이션 상세",
                )
              }
            >
              {formatterProps.row.sourceLocationName}
            </LinkAnchor>
          );
        },
      },
      {
        key: "orderDate",
        name: "수주일",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <Flex align="center">
              {formatterProps.row.orderDate !== null
                ? timeUtil(formatterProps.row.orderDate as string)
                : ""}
            </Flex>
          );
        },
      },
      {
        key: "createdAt",
        name: "접수일",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <Flex align="center">
              {formatterProps.row.createdAt !== null
                ? DatetimeUtil(formatterProps.row.createdAt as string)
                : ""}
            </Flex>
          );
        },
      },
      {
        key: "deliveryDeadline",
        name: "납기일",
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          return (
            <Flex align="center">
              {formatterProps.row.deliveryDeadline !== null
                ? timeUtil(formatterProps.row.deliveryDeadline as string)
                : ""}
            </Flex>
          );
        },
      },

      {
        key: "options",
        name: t("common.action"),
        sortable: true,
        resizable: true,
        formatter: (formatterProps) => {
          const updatePurchaseOrderActionView = () => {
            openModal(
              <PurchaseOrderUpdateForm formatterProps={formatterProps?.row} />,
              null,
              `${orderTitle} 수정`, // TODO: 다국어 처리
              true,
            );
          };

          const deletePurchaseOrderAction = () => {
            openModal(
              <ConfirmForm
                message={t("message.rowDelete")}
                yesCallback={() =>
                  deleteMutate({ purchaseOrderId: formatterProps?.row.id as number })
                }
                noCallback={() => {}}
                commonCallback={() => closeModal(null)}
              />,
              null,
              `${orderTitle} 삭제`, // TODO: 다국어 처리
            );
          };

          return (
            <Flex w="100%" h="100%" justify="center" align="center" gap="md">
              <ActionIcon variant="subtle" onClick={updatePurchaseOrderActionView} color="yellow">
                <IconEdit />
              </ActionIcon>
              <ActionIcon variant="subtle" onClick={() => deletePurchaseOrderAction()} color="red">
                <IconTrash />
              </ActionIcon>
            </Flex>
          );
        },
      },
    ],
    [deleteMutate, openModal, closeModal, t],
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("purchaseOrder.purchaseOrder")}` })}`,
        `${t("common.deleteFail", { item: `${t("purchaseOrder.purchaseOrder")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ purchaseOrderId: JSON.parse(row).id });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: `${orderTitle}` })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <Flex gap="sm">
          <Button
            leftIcon={<IconPlus />}
            onClick={() =>
              openModal(
                <PurchaseOrderCreateForm />,
                null,
                `${t("common.additional", { item: `${orderTitle}` })}`, // 수주 발주 추가
                true,
              )
            }
          >
            수주 추가
          </Button>
          <Button
            leftIcon={<IconPlus />}
            color="orange"
            disabled={[...selectedRows].length !== 1} // 1개만 선택
            onClick={() =>
              openModal(
                <PurchaseOrderItemsOutgoingForm
                  formatterProps={JSON.parse([...selectedRows].at(0))}
                />,
                null,
                "납품",
              )
            }
          >
            납품하기
          </Button>
        </Flex>
        <Flex gap="sm">
          <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
            {t("common.reset")}
          </Button>
          <Button rightIcon={<IconTrash />} color="red" onClick={() => handleDelete()}>
            {t("common.rowDelete")}
          </Button>
        </Flex>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: `${orderTitle}` })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: `${orderTitle}` })}</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<PurchaseOrdersGet200Response, PurchaseOrdersGet200ResponseRowsInner>
            columns={columns}
            rowKeyGetter={(row: PurchaseOrdersGet200ResponseRowsInner) => JSON.stringify(row)}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<PurchaseOrdersGet200Response> />
      </Flex>
    </Flex>
  );
};
