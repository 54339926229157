import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { setDateFormatStringWithTime } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import styled from "@emotion/styled";
import { Checkbox, Pagination, Table, Text } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface WorkInputLotProps {
  workId: number | undefined;
}

export const WorkInputLot = ({ workId }: WorkInputLotProps) => {
  const [activePage, setActivePage] = useState<number>(1);
  const { t } = useTranslation();
  const { data: workLogData } = useQuery(
    workLogs.get({
      query: {
        $and: [
          { workId: { $eq: workId ?? 0 } },
          { workLogType: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT },
        ],
      },
      sort: "-id",
      page: activePage,
      populate: [
        "itemUnit",
        "defectName",
        "alreadyCanceled",
        "creatorUser",
        "downtimeReasonName",
        "getEquipment",
      ],
    }),
  );

  const rows: any[] = workLogData?.data?.rows ?? [];

  return (
    <WorkTabBodyContainer>
      <TabBodyTitle>
        <Text fz="xl" fw={500}>
          {t("inputLot.inputLot")}
        </Text>
        <Text fz="xs" fw={400}>
          {t("inputLot.description")}
        </Text>
      </TabBodyTitle>
      <TabBodyTable>
        <Thead>
          <Tr>
            <Th width={70}>{t("routing.sequence")}</Th>
            <Th width={120}>{t("inputLot.material")}</Th>
            <Th width={120}>{t("lot.name")}</Th>
            <Th width={140}>{t("lot.lotExpiration")}</Th>
            <Th width={170}>{t("inputLot.materialLot")}</Th>
            <Th width={120}>{t("inputLot.inputLot")}</Th>
            <Th width={200}>{t("inputLot.createdAt")}</Th>
            <Th width={80}>{t("workLog.worker")}</Th>
            <Th width={100}>{t("inventory.quantity")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.length ? (
            rows.map((inputLot) => (
              <Tr>
                <Td width={70} style={{ justifyContent: "flex-end" }}>
                  {inputLot.id}
                </Td>
                {/* TODO: mantine UI -> NavLink 적용 필요 */}
                <Td width={120}>{inputLot.logData?.incoming?.itemData?.name}</Td>
                <Td width={120}>{inputLot.logData?.incoming?.lotData?.name}</Td>
                <Td width={140}>{inputLot.logData?.incoming?.lotData?.expiration}</Td>
                <Td width={170}>
                  <Checkbox
                    size="xs"
                    mr={12}
                    label={t("lot.name")}
                    defaultChecked={inputLot.logData?.incoming?.itemData?.uses_lot_name}
                    disabled
                  />
                  <Checkbox
                    size="xs"
                    label={t("lot.expiration")}
                    defaultChecked={inputLot.logData?.incoming?.itemData?.uses_lot_expiration}
                    disabled
                  />
                </Td>
                <Td width={120}>[LOT] id: {inputLot.logData?.incoming?.lotData?.id}</Td>
                <Td width={200}>{setDateFormatStringWithTime(inputLot.createdAt)}</Td>
                <Td width={80}>{inputLot.creatorUserName}</Td>
                <Td width={100} style={{ justifyContent: "flex-end" }}>
                  {setToLocaleString(inputLot.quantity)} {inputLot?.unitText}
                </Td>
              </Tr>
            ))
          ) : (
            <NoDataTr>
              <Text>{t("inputLot.notFound")}</Text>
            </NoDataTr>
          )}
        </Tbody>
      </TabBodyTable>
      <PaginationWrapper>
        <Pagination
          onChange={setActivePage}
          value={activePage}
          total={workLogData?.data?.totalPages ?? 0}
          size="lg"
          radius="sm"
        />
      </PaginationWrapper>
    </WorkTabBodyContainer>
  );
};

const WorkTabBodyContainer = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 0.5rem;
`;

const TabBodyTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const TabBodyTable = styled(Table)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Tr = styled.tr`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
  flex: ${(props) => (props.width ? `0 0 auto` : `1 0 0`)};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}px` : `auto`)};
  flex: ${(props) => (props.width ? `0 0 auto` : `1 0 0`)};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// const LeftSpace = styled.span`
//   width: 32px;
//   align-self: stretch;
//   background: ${theme.colors?.gray?.[3]};
//   border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
//   opacity: 0.2;
// `;

// const RightSpace = styled.span`
//   flex: 1 0 0;
//   align-self: stretch;
//   background: ${theme.colors?.gray?.[3]};
//   border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
//   opacity: 0.2;
// `;

const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const NoDataTr = styled.tr`
  display: flex;
  width: 100%;
  height: 4rem;
  justify-content: center;
  align-items: center;
`;
