// @fileoverview : 영업관리 > 재고계획

import { mutateStockPlans } from "@/api/stockPlan/useStockPlanQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { PartnerDetailForm } from "@/features/partner/form/detailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import {
  flexGrowable,
  flexStable,
  StandardLayoutTableWrapper,
} from "@/features/standardLayout/Styles";
import { StockPlanCreateForm } from "@/features/stockPlan/form/createForm";
import { StockPlanIncomingForm } from "@/features/stockPlan/form/stockPlanIncomingForm";
import { StockPlanOutgoingForm } from "@/features/stockPlan/form/stockPlanOutgoingForm";
import { StockPlanTransportationForm } from "@/features/stockPlan/form/transportationForm";
import { StockPlanUpdateForm } from "@/features/stockPlan/form/updateForm";
import { TransportationDetailForm } from "@/features/transportation/form/transportationDetailForm";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Avatar, Button, Flex, Text, Tooltip } from "@mantine/core";
import {
  StockPlansGet200Response,
  StockPlansGet200ResponseRowsInner,
  WmsApiStockPlansStockPlanIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import {
  IconCodePlus,
  IconEdit,
  IconFilterPlus,
  IconMinus,
  IconPlus,
  IconRecycle,
  IconTrash,
} from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { Column, SelectColumn } from "react-data-grid";
import { useTranslation } from "react-i18next";

export const StockPlanPage = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, setSelectedRows, refetch, resetQueryStrings } = useStandardLayout();
  const { t } = useTranslation();

  const { mutate: deleteMutate } = useMutation(
    (params: WmsApiStockPlansStockPlanIdDeleteRequest) =>
      mutateStockPlans
        .delete(params)
        .mutationFn(params as any | WmsApiStockPlansStockPlanIdDeleteRequest),
    {
      onSuccess: () => {
        refetch();
        customAlert("재고계획 삭제에 성공하였습니다.", "삭제 성공", "green");
      },
      onError: () => {
        customAlert("재고계획 삭제에 실패하였습니다.", "삭제 실패", "red");
      },
    },
  );

  const columns: readonly Column<StockPlansGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "scheduledAt",
      name: "예정일시",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex>{timeUtil(formatterProps.row.scheduledAt ?? "")}</Flex>;
      },
    },
    {
      key: "transportationId",
      name: "배차",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() => {
              openModal(
                <TransportationDetailForm transportationID={formatterProps.row.transportationId} />,
                null,
                "배차 상세",
              );
            }}
          >
            {formatterProps.row.transportationId}
          </LinkAnchor>
        );
      },
    },
    {
      key: "partnerCode",
      name: "협력사",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <PartnerDetailForm partnerCode={formatterProps.row.partnerCode} />,
                null,
                "협력사 상세",
              )
            }
          >
            {formatterProps.row.partnerCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "itemCode",
      name: "품목",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <ItemsDetailForm itemCode={formatterProps?.row?.itemCode} />,
                null,
                "품목 상세",
              )
            }
          >
            {formatterProps.row.itemCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "lotId",
      name: "로트",
      sortable: true,
      resizable: true,
    },
    {
      key: "quantity",
      name: "계획 대비 완료 수량",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return <Flex justify="flex-end">{formatterProps.row.quantity}</Flex>;
      },
    },
    {
      key: "sourceLocationCode",
      name: "출발 로케이션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps.row.sourceLocationCode} />,
                null,
                "로케이션 상세",
              )
            }
          >
            {formatterProps.row.sourceLocationCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "targetLocationCode",
      name: "도착 로케이션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps.row.targetLocationCode} />,
                null,
                "로케이션 상세",
              )
            }
          >
            {formatterProps.row.targetLocationCode}
          </LinkAnchor>
        );
      },
    },
    {
      key: "purchaseOrderItemId",
      name: "수주서",
      sortable: true,
      resizable: true,
    },
    // {
    //   key: "createdAt",
    //   name: "예상 부족 수량",
    //   sortable: true,
    //   resizable: true,
    // },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateStockPlanActionView = () => {
          openModal(
            <StockPlanUpdateForm formatterProps={formatterProps?.row} />,
            null,
            `${t("common.edit", { item: "재고계획" })}`, // 재고계획 수정
            true,
          );
        };

        const deleteStockPlanAction = () => {
          openModal(
            <ConfirmForm
              message={t("message.rowDelete")}
              yesCallback={() => deleteMutate({ stockPlanId: formatterProps?.row.id as number })}
              noCallback={() => {}}
              commonCallback={() => closeModal(null)}
            />,
            null,
            `${t("common.delete", { item: "재고계획" })}`, // 재고계획 삭제
          );
        };

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon variant="subtle" onClick={updateStockPlanActionView} color="yellow">
              <IconEdit />
            </ActionIcon>
            <ActionIcon variant="subtle" onClick={() => deleteStockPlanAction()} color="red">
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: "재고계획" })}`,
        `${t("common.deleteFail", { item: "재고계획" })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ stockPlanId: JSON.parse(row).id });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      `${t("common.delete", { item: "재고계획" })}`,
    );
  };

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <Flex gap="sm">
          <Button
            leftIcon={<IconPlus />}
            onClick={() => openModal(<StockPlanCreateForm />, null, "재고계획 추가", true)}
          >
            재고계획 추가
          </Button>
          <Button
            leftIcon={<IconPlus />}
            color="orange"
            // disabled={!isRowBtnActive || data.sourceLocationCode === null}
            disabled={
              !([...selectedRows].length === 1) ||
              ([...selectedRows] as StockPlansGet200ResponseRowsInner).targetLocationCode === null
            }
            onClick={() =>
              openModal(
                <StockPlanIncomingForm formatterProps={JSON.parse([...selectedRows].at(0))} />,
                null,
                "입고",
              ).then((result) => {
                if (result) {
                  // 로직이 정상적으로 처리되면 선택된 행을 초기화
                  setSelectedRows((): Set<any> => new Set());
                }
              })
            }
          >
            입고
          </Button>
          <Button
            leftIcon={<IconMinus />}
            disabled={
              !([...selectedRows].length === 1) ||
              ([...selectedRows] as StockPlansGet200ResponseRowsInner).targetLocationCode === null
            }
            color="orange"
            onClick={() =>
              openModal(
                <StockPlanOutgoingForm formatterProps={JSON.parse([...selectedRows].at(0))} />,
                null,
                "출고",
              ).then((result) => {
                if (result) {
                  // 로직이 정상적으로 처리되면 선택된 행을 초기화
                  setSelectedRows((): Set<any> => new Set());
                }
              })
            }
          >
            출고
          </Button>
          <Button
            leftIcon={<IconCodePlus />}
            color="green"
            disabled={!([...selectedRows].length > 0)}
            onClick={() =>
              openModal(
                <StockPlanTransportationForm
                  stockPlanId={[...selectedRows].map(
                    (row) => (JSON.parse(row) as StockPlansGet200ResponseRowsInner).id as number,
                  )}
                />,
                null,
                "배차 추가",
                true,
              ).then((result) => {
                if (result) {
                  // 로직이 정상적으로 처리되면 선택된 행을 초기화
                  setSelectedRows((): Set<any> => new Set());
                }
              })
            }
          >
            배차 일괄 추가
          </Button>
        </Flex>
        <Flex gap="sm">
          <Button rightIcon={<IconRecycle />} color="teal" onClick={() => resetQueryStrings()}>
            {t("common.reset")}
          </Button>
          <Button rightIcon={<IconTrash />} color="red" onClick={() => handleDelete()}>
            {t("common.rowDelete")}
          </Button>
        </Flex>
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>
          {t("common.list", { item: "재고계획" })}
        </Text>
        <Text fz="xs">{t("common.subTitle", { item: "재고계획" })}</Text>
        {/* 고객으로부터의 주문 */}
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={t("common.filterDescription")}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<StockPlansGet200Response, StockPlansGet200ResponseRowsInner>
            columns={columns}
            rowKeyGetter={(row: StockPlansGet200ResponseRowsInner) => JSON.stringify(row)}
          />
        </StandardLayoutTableWrapper>
        <PaginationBox<StockPlansGet200Response> />
      </Flex>
    </Flex>
  );
};
