import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { useStandardLayout } from "@/features/standardLayout/Context";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { useLoader } from "@/hooks/useLoader";
import { getUserCodeByUser } from "@/utils/checkData";
import { Button, Flex } from "@mantine/core";
import {
  PurchaseOrdersGet200Response,
  PurchaseOrdersGet200ResponseRowsInner,
  ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { OrderProvider } from "../context/OrderProvider";
import { OrderCreateForm } from "../form/createForm";
import { OrderItemsIncomingForm } from "../form/orderItemsIncomingForm";
import { OrderItemsIncomingProps } from "../hook/useOrderItemsIncoming";

export const OrderActionHeader = ({ setLoading }: OrderItemsIncomingProps) => {
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const userCode = getUserCodeByUser();
  const { selectedRows, refetch, data, setSelectedRows } = useStandardLayout();
  const { LoadingOverlay } = useLoader();

  const setData = (data as AxiosResponse<PurchaseOrdersGet200Response, any>)?.data?.rows
    ?.filter((item) => selectedRows.has(item.id))
    ?.map((item) => item);

  const { mutate: deleteMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
      mutatePurchaseOrders
        .delete(params)
        .mutationFn(params as any | ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest),
    {
      onSuccess: () => {
        refetch();
        customAlert(
          `${userCode === "FLOUR" ? t("message.deleteSuccess", { item: `${t("purchaseOrder.purchaseOrder")}` }) : t("message.deleteSuccess", { item: `${t("order.order")}` })}`,
          t("common.deleteSuccess"),
          "green",
        );
        closeModal(null);
      },
      onError: (error: any) => {
        customAlert(
          `${error.response?.data?.message}` ??
            `${userCode === "FLOUR" ? t("message.deleteFail", { item: `${t("purchaseOrder.purchaseOrder")}` }) : t("message.deleteFail", { item: `${t("order.order")}` })}`,
          t("common.deleteFail"),
          "red",
        );
      },
    },
  );

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert(
        `${t("message.deleteSelect", { item: `${t("order.order")}` })}`,
        `${t("common.deleteFail", { item: `${t("order.order")}` })}`,
        "red",
      );
      return;
    }

    openModal(
      <ConfirmForm
        message={t("message.rowDelete")}
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ purchaseOrderId: row });
          });

          selectedRows.clear();
        }}
        noCallback={() => {}}
        commonCallback={() => closeModal(null)}
      />,
      null,
      t("common.delete", { item: `${t("order.order")}` }),
    );
  };

  return (
    <Flex w="100%" justify="space-between" wrap="wrap" p="sm">
      <LoadingOverlay />
      <Flex gap="sm" justify="flex-start">
        <Button
          onClick={() =>
            openModal(
              <OrderCreateForm />,
              null,
              `${userCode === "FLOUR" ? t("common.additional", { item: `${t("purchaseOrder.purchaseOrder")}` }) : t("common.additional", { item: `${t("order.order")}` })}`,
              true,
            )
          }
          leftIcon={<IconPlus />}
        >
          추가
        </Button>
        <Button
          leftIcon={<IconPlus />}
          color="orange"
          disabled={[...selectedRows].length !== 1}
          onClick={() =>
            openModal(
              <OrderProvider>
                <OrderItemsIncomingForm
                  purchaseOrder={setData && (setData[0] as PurchaseOrdersGet200ResponseRowsInner)}
                  setLoading={setLoading}
                />
              </OrderProvider>,
              null,
              t("inventory.incoming"),
            ).then((res) => {
              if (res) {
                setSelectedRows((): Set<any> => new Set());
              }
            })
          }
        >
          {t("inventory.incoming")}
        </Button>
      </Flex>
      {customFunctions.ADD_ORDER_ACTION_BUTTON ? (
        <></>
      ) : (
        <Flex gap="sm">
          <Button rightIcon={<IconTrash />} color="red" onClick={() => handleDelete()}>
            {t("common.rowDelete")}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
