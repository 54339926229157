import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { useOrderItemsIncoming } from "@/features/order/hook/useOrderItemsIncoming";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { FormActionButtonGroup } from "@/features/ui/formActionButtonGroup/FormActionButtonGroup";
import { NewLotCreateInput } from "@/features/ui/newLotCreateInput/NewLotCreateInput";
import { MantineSelectableTable } from "@/features/ui/table/MantineSelectableTable";
import { useCreatePurchaseDeliveryIncomingWithLot } from "@/hooks/useCreatePurchaseDeliveryIncomingWithLot";
import { Flex, NumberInput, Space, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
  ItemsGet200ResponseRowsInnerLotsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { Dispatch, SetStateAction, useEffect } from "react";

interface PurchaseOrderItemsSelectFormProps {
  purchaseOrder: AuthSignupPost201ResponseEquipmentPurchaseOrderItem | undefined;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const DeliveryIncoming = Object.assign(
  {},
  {
    TextInput: TextInput,
    NumberInput: NumberInput,
    NewLotCreateInput: NewLotCreateInput,
    LocationAutoComplete: LocationAutoComplete,
    FormActionButtonGroup: FormActionButtonGroup,
    MantineSelectableTable: MantineSelectableTable,
  },
);

export interface DeliveryIncomingFormProps
  extends AuthSignupPost201ResponseEquipmentPurchaseOrderItem {
  lotId?: number;
  lotInfo?: {
    name: string | undefined;
    expiration: string | undefined;
  };
  isChecked?: boolean;
  inputValue?: number;
}

export const DeliveryIncomingForm = ({
  purchaseOrder,
  setLoading,
}: PurchaseOrderItemsSelectFormProps) => {
  const { closeModal } = useModal();
  const { orderItemsSubmit, isLoading } = useOrderItemsIncoming({ setLoading });

  const { updatedPurchaseDeliveryIncomingWithLot } = useCreatePurchaseDeliveryIncomingWithLot({
    items: [purchaseOrder as AuthSignupPost201ResponseEquipmentPurchaseOrderItem],
  });
  const form = useForm<DeliveryIncomingFormProps>({
    initialValues: {},
  });

  const usesLotName = form.values.usesLotName;
  const usesLotExpiration = form.values?.usesLotExpiration;
  const isLotCreationEnabled = !usesLotName && !usesLotExpiration;

  const lotDescription = (() => {
    if (usesLotName && usesLotExpiration) return "이름 + 만료일";
    if (usesLotName) return "이름";
    if (usesLotExpiration) return "만료일";
    return "관리 안함";
  })();

  const handleLotCreation = (data: ItemsGet200ResponseRowsInnerLotsInner) => {
    form.setValues({
      ...form.values,
      lotId: data?.id as number,
      lotInfo: {
        name: data.name,
        expiration: data?.expiration,
      },
    });
  };

  const onSubmit = () => {
    orderItemsSubmit({ item: form.values, targetLocationCode: form.values.targetLocationCode });
    if (!isLoading) closeModal({});
  };

  useEffect(() => {
    if (updatedPurchaseDeliveryIncomingWithLot) {
      form.setValues({
        ...updatedPurchaseDeliveryIncomingWithLot?.[0],
        inputValue: Number(purchaseOrder?.inputEligibleQuantity),
      });
    }
  }, [updatedPurchaseDeliveryIncomingWithLot]);

  return (
    <>
      {
        <Flex direction={"column"} gap={30}>
          <DeliveryIncoming.TextInput
            disabled
            label="품목코드"
            withAsterisk
            value={form.values.itemCode}
          />
          {customFunctions.ADD_ORDER_AUTO_CREATE_LOT ? (
            <>
              <DeliveryIncoming.TextInput
                disabled
                label="LOT 정보"
                withAsterisk
                value={updatedPurchaseDeliveryIncomingWithLot?.[0]?.lotInfo?.name}
                error={purchaseOrder?.usesLotName ? undefined : "로트를 사용하지 않는 품목입니다."}
              />
              <DeliveryIncoming.NumberInput
                label="분할개수"
                min={1}
                disabled={!purchaseOrder?.usesLotName}
                {...form.getInputProps("purchaseOrderItemQty")}
                value={Number(form.values.purchaseOrderItemQty ?? "1")}
                error={
                  purchaseOrder?.usesLotName
                    ? undefined
                    : "로트가 존재하지 않아 분할을 하실 수 없습니다."
                }
              />
            </>
          ) : (
            <DeliveryIncoming.NewLotCreateInput
              lotDescription={lotDescription}
              disabled={isLotCreationEnabled}
              itemCode={form.values.itemCode as string}
              newIdValue={form.values.lotId as number}
              defaultValue={form.values.lotId?.toString() ?? ""}
              onLotCreated={handleLotCreation}
              onRowChange={(lot) => {
                form.setFieldValue(`lotId`, Number(lot.value));
                form.setFieldValue(`lotInfo`, {
                  name: lot.label,
                  expiration: lot.expiration === "null" ? undefined : lot.expiration,
                });
              }}
            />
          )}
          <DeliveryIncoming.LocationAutoComplete
            label="로케이션코드"
            withAsterisk
            defaultValue={purchaseOrder?.targetLocationCode}
            {...form.getInputProps("locationCode")}
          />
          <DeliveryIncoming.NumberInput
            max={Number(form.values?.inputEligibleQuantity)}
            label="수량"
            withAsterisk
            type="number"
            {...form.getInputProps(`inputValue`)}
            value={Number(form.values.inputValue)}
          />
          {!customFunctions.ADD_ORDER_AUTO_CREATE_LOT && (
            <DeliveryIncoming.TextInput
              disabled
              label="만료일"
              value={form?.values?.lotInfo?.expiration}
            />
          )}
          <Space h="xl" />
          <DeliveryIncoming.FormActionButtonGroup
            form={form}
            onCancel={() => closeModal({})}
            onSubmit={onSubmit}
          >
            {"입고"}
          </DeliveryIncoming.FormActionButtonGroup>
        </Flex>
      }
    </>
  );
};
